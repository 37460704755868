import React, { useState, useRef } from 'react'
import ReactPlayer from "react-player";
import moment from 'moment';
import { useDispatch, useSelector} from 'react-redux';
import { SET_STREAMING } from '../redux/actions/types';

import '../index.css';
import commonData from '../utils/commonData';
import { Typography } from '@mui/material';


export default function VideoPlayer() {
  const dispatch = useDispatch();
  const [isReady, setIsReady] = useState(false);
  const stream = useSelector(({stream}) => stream);
  const videoRef = useRef(null);


  const handlePlay = () => {
    if(!stream.isPlaying){

      let { start } = stream.segment;
      let timelapseNow = moment().diff(moment(stream.isStreaming), 'seconds');
      let timelapse = timelapseNow - start;
        videoRef.current.seekTo(timelapse, "seconds");
    }


    dispatch({type: SET_STREAMING, payload: {isPlaying: !stream.isPlaying}})
  }

  const onReady = React.useCallback(() => {
  
    if (!isReady) {
    
        if(stream.timelapse){
          videoRef.current.seekTo(stream.timelapse, "seconds");
        } else {
          videoRef.current.seekTo(0, "seconds");

        }
      setIsReady(true);
    }
  
  }, [isReady]);

  const handleProgress = (e) => {
    if(stream.isStreaming){
   const currentTime = videoRef.current.getCurrentTime();
      let { start } = stream.segment;
    let timelapseNow = moment().diff(moment(stream.isStreaming), 'seconds');
    let timelapse = timelapseNow - start;
    if((currentTime - 10)  > timelapse){
      dispatch({type: SET_STREAMING, payload: {isPlaying: false}})
    }  

    if(currentTime < (timelapse - 10)){
      videoRef.current.seekTo(timelapse, "seconds");
      dispatch({type: SET_STREAMING, payload: {isPlaying: true}})
    }
  }
 };

 


  return (
     <>
     <div className='stream-player-container'>
      <div className='stream-header-overlay'>
        <div className='live-text'>
        <div className='blink-dot blinking'></div> LIVE
        </div>
      <div className='live-timer'>
        <small>{moment().format('L')}&nbsp;{moment().format('LT')}</small>
        </div>
        <div className='text-danger text-bolder'>
        {/* CFC NATIONAL */}
        </div>
        </div>
     <ReactPlayer
                ref={videoRef}
        autoPlay={stream.autoPlay}
        muted={stream.isMuted}
        width="100%"
        height="100%"
        onProgress={handleProgress}
        controls={stream.controls}
        playing={stream.isPlaying}
        // url={`${commonData.apiUrl}/stream/live/${stream.segment.id}`}
        url={`${commonData.assetsUrl}/1.mp4`}
        playsInline
        onReady={() => onReady()}
            />
  
     </div>
         </>
  )
}
