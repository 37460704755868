import utf8Enc from "crypto-js/enc-utf8";
import aes from "crypto-js/aes";
import "./styles.css";
import axios from "axios";
import { useState } from "react";


export function textToBlob(text) {
  return new Blob([text], { type: "text/plain" });
}

function blobAsDataUrl(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export default function TestPage() {
  const [dataUrl, setDataUrl] = useState("");
  const [dataUrl2, setDataUrl2] = useState("");
  const doText = async () => {
    const message = `Test message: ${new Date()}`;
    console.log({ message });
    const blob = textToBlob(message);
    console.log({ blob });
    const dataUrl = await blobAsDataUrl(blob);
    setDataUrl(dataUrl);
    console.log({ bs: dataUrl });
    const ps = "whatever";
    const encrypted = aes.encrypt(message, ps).toString();
    console.log({ encrypted });

    const decrypted = aes.decrypt(encrypted, ps).toString(utf8Enc);
    setDataUrl2(decrypted);
    console.log(decrypted);
    console.log("decrypted === dataUrl", decrypted === message);
  };

  const doImage = async () => {
    const res = await axios.get("https://picsum.photos/200", {
      responseType: "blob"
    });
    const blob = res.data;
    console.log({ blob });
    const dataUrl = await blobAsDataUrl(res.data);
    setDataUrl(dataUrl);
    console.log({ bs: dataUrl });
    const ps = "whatever";
    const encrypted = aes.encrypt(dataUrl, ps).toString();
    console.log({ encrypted });

    const decrypted = aes.decrypt(encrypted, ps).toString(utf8Enc);
    console.log({ decrypted });
    setDataUrl2(decrypted);

    console.log("decrypted === dataUrl", decrypted === dataUrl);
  };

  return (
    <div className="App">
      <b>Encrypt and decrypt data urls</b>
      <br />
      <br />
      <button onClick={doText}>Do Text</button>
      <button onClick={doImage}>Do Image</button>
      <p>See Console</p>
      <table>
        <tbody>
          <tr>
            <td>
              <iframe
                title="dataUrl"
                src={dataUrl}
                width="100%"
                height="300px"
              />
            </td>
            <td>
              <iframe
                title="dataUrl2"
                src={dataUrl2}
                width="100%"
                height="300px"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
