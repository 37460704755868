
import io from 'socket.io-client';
import commonData from './commonData';
import { decryptData } from './encryption';





  let socket;
  export const initiateSocketConnection = (token) => {
    socket = io(commonData.socketUrl, {
      auth: {
        token
      },
      transports: [ "websocket" ]
    });
    

    

  }



  export const disconnectSocket = () => {
    if(socket) socket.disconnect();
   
  }





  // Handle message receive event
  export const setEvent = (cb) => {
    if (!socket) return(true);
    socket.on('set-event', event => {
      return cb(null, event);
    });
  }

  export const getEvent = (cb) => {
    if (!socket) return(true);
    socket.on('get-event', event => {
      return cb(null, event);
    });
  }

  // Handle message receive event
  export const subscribeToBetting = (cb) => {
    if (!socket) return(true);
    socket.on('betting', msg => {
      return cb(null, decryptData(msg));
    });
  }

    // Handle message receive event
    export const subscribeToEvent = (cb) => {
      if (!socket) return(true);
      socket.on('event-update', event => {
        return cb(null, decryptData(event));
      });
    }
  

    // Handle message receive event
    export const setTimelapse = (cb) => {
      if (!socket) return(true);
      socket.on('timelapse', msg => {
        return cb(null, msg);
      });
    }
  
  
    export const getTimelapse = (cb) => {
      if (socket) socket.emit('timelapse', { }, cb);
    }




  // Handle message receive event
  export const subscribeToMessages = (cb) => {
    if (!socket) return(true);
    socket.on('message', msg => {
      return cb(null, msg);
    });
  }
  
  export const joinRoom = (roomName) => {
    socket.emit('join', roomName);
  }
  
  export const sendMessage = ({message, roomName}, cb) => {
    if (socket) socket.emit('message', { message, roomName }, cb);
  }








































//   useEffect(() => {

  
//     // if(isAuthUser){




  
//     socket.on('betting', (betting) => {
//       dispatch({type: SET_BETS, payload: betting})
//     });
  
//     socket.on('timelapse', (val) => {
//       dispatch({type: SET_TIMELAPSE, payload: val})
//     });
    
    
//     socket.on('event-update', (val) => {
//       let { fightNo, fightStatus, results, win} = val; 

//       dispatch({type: SET_FIGHT, payload: {...currentFight, fightStatus: fightStatus, fightNo, win }})
//       dispatch({type: SET_RESULTS, payload: results})

//       // window.location.href = '/';
//   });

  
//     socket.on('event-end', () => {
//         window.location.href = '/';
//     });
  
  
  
//     socket.on('stop-streaming', (streaming) => {
//       dispatch({type: STOP_STREAMING})
//       // props.history.push('/home')
//       // window.location.href = '/home';
//     });
  
//     socket.on('disconnect', () => {
//       dispatch({type: STOP_STREAMING})
//     });
    
//   // }

//     return () => {
    
//       // socket.disconnect();
//       // socket.off('connect');
//       // socket.off('disconnect');
//       // socket.off('streaming');
//       // socket.off('betting');
//       // socket.off('get-balance');
//       // socket.off('event-end');
//       // socket.off('stop-streaming');

//     };
    
//   }, [])
  