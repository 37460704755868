import {  CLEAR_EVENT, CLEAR_MATCH, CLEAR_SEGMENT, CLEAR_SOCKET, SET_ACCOUNTS, SET_EVENT, SET_EVENTS, SET_MATCH, SET_MATCHES, SET_SEGMENT, SET_SOCKET_ID, SET_STREAMING, SET_TIMELAPSE, STOP_STREAMING } from "../actions/types";

const INIT_MATCH = {
  start: 0,
  open: 0,
  lastCall: 0,
  close: 0,
  end: 0,
  duration: 0,
  fightNo: '',
  win: ''
}

const INIT_EVENT = {
  title: '',
  subtitle: '',
  duration: 0,
  segments: [],
  totalFight: 0,
  isStreaming: false
}

const INIT_STATE = {
    socketId: null,
    accounts: [],
    timelapse: 0,
    isStreaming: false,
    matches: [],
    match: INIT_MATCH,
    events: [],
    event: INIT_EVENT,
    segment: null,
    videoPlayer: {
      muted: true,
      playing: false,
      controls: true,
      duration: 0
    }
};

export default (state = INIT_STATE, action) => {
  const {type, payload} = action;

  switch (type) {
    case SET_SOCKET_ID: {
      return {
        ...state,
        socketId: payload
      };
    }
    
    case SET_ACCOUNTS: {
      return {
        ...state,
        accounts: payload
      };
    }
    

    case CLEAR_SOCKET: {
      return {
        ...state,
        socketId: null
      };
    }

    case SET_STREAMING: {
      return {
        ...state,
        isStreaming: true
      };
    }

    case STOP_STREAMING: {
      return {
        ...state,
        isStreaming: false
      };
    }

    case SET_TIMELAPSE: {
      return {
        ...state,
        timelapse: payload
      };
    }

    case SET_EVENTS: {
      return {
        ...state,
        events: payload
      };
    }
    
    case SET_EVENT: {
      return {
        ...state,
        event: payload
      };
    }

    
    case SET_MATCHES: {
      return {
        ...state,
        matches: payload
      };
    }


    case SET_MATCH: {
      return {
        ...state,
        match: payload
      };
    }

    case SET_SEGMENT: {
      return {
        ...state,
        segment: payload
      };
    }

    case CLEAR_SEGMENT: {
      return {
        ...state,
        segment: payload
      };
    }

    case CLEAR_MATCH: {
      return {
        ...state,
        match: INIT_MATCH
      };
    }

    case CLEAR_EVENT: {
      return {
        ...state,
        event: INIT_EVENT
      };
    }

    default:
      return state;
  }
};
