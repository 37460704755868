import {   SET_SOCKET_ID, SET_STREAM, SET_STREAMING, SET_TIMELAPSE, STOP_STREAMING, SET_RESULTS, CLEAR_BETS } from "../actions/types";


const INIT_STATE = {
    isStreaming: null,
    segment: {
      id: null,
      start: null
    },
    timelapse: 0,
    isPlaying: false,
    controls: false,
    isMuted: false,
    autoPlay: false,
    streamUrl: null
};

export default (state = INIT_STATE, action) => {
  const {type, payload} = action;

  switch (type) {
    
    case SET_STREAMING: {
      return {
        ...state,
        ...payload
      };
    }

    case STOP_STREAMING: {
      return {
        ...INIT_STATE
        };
    }
    

    case SET_TIMELAPSE: {
      return {
        ...state,
        timelapse: payload
      };
    }


    default:
      return state;
  }
};
