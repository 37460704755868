import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Card, Box, CardContent, Typography, IconButton, CardHeader} from '@mui/material';


//Icons
import MessageIcon from '@mui/icons-material/Message';


const rulesBetting = ['FIGHT WITH 107 AND BELLOW PAYOUT, SHALL BE CANCELLED.'];

const rulesOnLoad = ['CFC IS NOT RESPONSIBLE FOR YOUR CASH-IN/CASH-OUT USING DIFFERENT ACCOUNT NUMBERS.', 'WE CAN ONLY CATER TO THOSE CASH-IN/CASH-OUT UNDER OUR ACCOUNT NUMBERS.', ' WE ARE NOT RESPONSIBLE TO ANY AGREEMENT YOU MADE WITH YOUR COORDINATION.']



export default function RecipeReviewCard() {



  return (
  <>
      <CardHeader
        className="gcash-header"
        title={<Typography className='text-white text-bold'>HELP</Typography>}
      />
 
        <CardContent className="dialog-content text-white p-4">
      
          <Typography className='text-15 text-bold'>RULES ON BETTING</Typography>      
          <ol className='m-3'>
          {rulesBetting.map((a, index) => {
          return (
            <li className='mb-3' key={index}>
            <Typography variant='body1' className="text-12">
            {a}
          </Typography>
            </li>
          )
          })}
          
          </ol>
          <br/>
          <Typography className='text-15 text-bold'>RULES ON LOAD</Typography>      
          <ol className='m-3'>
          {rulesOnLoad.map((a, index) => {
          return (
            <li className='mt-4 mb-4' key={index}>
            <Typography variant='body1' className="text-12">
            {a}
          </Typography>
            </li>
          )
          })}
          
          </ol>
          <br/>
          <Box className='user-profile'>
          <div className='user-actions'>
          <button className='btn-contact p-1 text-sm-rem  ml-3 mr-3'  disabled style={{opacity: 0.5}}><MessageIcon/> <strong>CONTACT CUSTOMER SUPPORT</strong></button>
          </div>
          </Box>
        </CardContent>
        </>
  );
}
