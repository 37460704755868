import * as React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {MenuItem, Dialog, Chip, Divider, Button, Box, DialogTitle, DialogContent, FormControl, FormLabel, FormGroup, TextField,  IconButton, Typography, InputAdornment} from '@mui/material';
//Icons
import CloseIcon from '@mui/icons-material/Close';

import { useDispatch, useSelector } from 'react-redux';
import { CLOSE_MODALS, SET_ACCOUNTS } from '../../redux/actions/types';
import { getManagedAccounts } from '../../redux/actions/Data';
import MenuButton from '../MenuButton';

const FILTER_BY = [
    {label: 'Approval Players', value: 'approvals'},
    {label: 'Deleted Players', value: 'deleted'},
]


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="small"
          sx={{
            position: 'absolute',
            right: 4,
            top: 4,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function PlayerLists() {
  const dispatch = useDispatch();

  const { accounts} = useSelector(({dataReducer}) => dataReducer);
  const { isWeb, usersModal }  = useSelector(({uiReducer}) => uiReducer);
    const [counts, setCounts] = useState({agents: 0, players: 0, approvals: 0, deleted:0})
    const [activeFilter, setActiveFilter] = useState('approvals')
    
  

  const handleClose = () => {
    dispatch({type: CLOSE_MODALS})
  };

  
  
  const handleGetAccounts = (type) => {
    dispatch(getManagedAccounts(type))
    .then(res => {      
        let {d, c} = res;
        dispatch({type: SET_ACCOUNTS, payload: d})
        setCounts(c)
    })
    .catch(err => {
        console.log('SOMETHING WENT WRONG!')
    })
  }
  
  useEffect(() => {
    if(usersModal){
        handleGetAccounts(activeFilter);
    }
    
    return () => {
        dispatch({type: SET_ACCOUNTS, payload: []})
    }
  }, [usersModal, activeFilter])
  
  

  return (
    <div>
      <BootstrapDialog
        onClose={() => handleClose()}
        aria-labelledby="customized-dialog-title"
        open={usersModal}
        maxWidth="sm"
        fullWidth
        fullScreen={!isWeb ? true : false}
      >
        <BootstrapDialogTitle id="customized-dialog-title"   onClose={() => handleClose()} className="bg-dark-blue text-white text-15">
        &nbsp;&nbsp;Manage Accounts
        </BootstrapDialogTitle>
        <Box
        className="p-3 dialog-content text-white"
        >
        <FormLabel component="legend" className='text-light input-label'>Filter By</FormLabel>
        <FormGroup aria-label="position" row>
            <TextField
                id="outlined-size-small"
                size="small"
                fullWidth
                select
                className='login-input'
                defaultValue={activeFilter}
                value={activeFilter}
                onChange={(e) => setActiveFilter(e.target.value)}
              >
              {FILTER_BY.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label} ({counts[option.value]})
                </MenuItem>
              ))}
              </TextField>
        </FormGroup>
        <Divider className='text-white bg-white w-100 pofile-divider'/>
          
        </Box>

        <DialogContent dividers 
        className="dialog-content text-white"
        >

        <br/>

        <div className="transaction-list">
          {accounts.map(a => {
            return (
              <div key={a.userId} className="transaction-list-item mb-3">
              <div className="transaction-list-item-text">
              <strong className='text-primary text-1-rem'>{a.username}</strong>&nbsp;{a.role === 'agent' ?  <Chip size='small' label={`Agent - (${a.comRate}%)`} color="primary" variant="contained" className="chip" /> : a.role === 'user' ? <Chip size='small' label="Player" color="success" variant="contained" className="chip" /> : ''}
              <br/>   
              <small> Points: </small><span className="text-yellow">₱{Number(a.points).toFixed(2)}</span>
              </div>
            { a.isBan ?   <div className="transaction-list-item-text">
            <Chip size='small' label="Banned User" color="error" variant="contained" className="chip" />  
        </div> : (!a.isActivated ? <div className="transaction-list-item-text">
        <Chip size='small' label="Unverified" color="warning" variant="contained" className="chip" />  
    </div> :  <Box style={{flexGrow: 1}}>
                  
    </Box>) }
             
              <div
              className="transaction-list-action text-center">
              <MenuButton data={a} onClose={() => handleClose()}/>
              </div>
              <hr className="text-dark"/>
            </div>
            )

          })}
       
        
         </div>

    
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
