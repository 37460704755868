import { UPDATE_AUTH_USER, CLEAR_USER, SET_POINTS, CLEAR_POINTS, SET_STREAM_URL, CLEAR_STREAM_URL, SET_TRANSACTIONS, SET_CASH_REQUESTS, LOGIN } from '../actions/types';

const INIT_USER = {
  points: 0,
  load: 0,
  commission: 0,
  role: 'user'
}

const INIT_STATE = {
  isAuthUser: false,
  user: INIT_USER,
  stream_url: null,
  transactions: [],
  cashRequests: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_AUTH_USER: {
      return {
        ...state,
        user: action.payload
      };
    }
    
    case LOGIN: {
      return {
        ...state,
        isAuthUser: true 
        };
    }
  
    case SET_TRANSACTIONS: {
      return {
        ...state,
        transactions: action.payload
      };
    }


    case SET_CASH_REQUESTS: {
      return {
        ...state,
        cashRequests: action.payload
      };
    }




    case CLEAR_USER: {
      return {
        ...state,
        isAuthUser: false,
        user: INIT_USER
      };
    }

    
    case SET_STREAM_URL: {
      return {
        ...state,
        stream_url:  action.payload
      };
    }

    
    case CLEAR_STREAM_URL: {
      return {
        ...state,
        stream_url: action.payload
      };
    }

    case SET_POINTS: {
      return {
        ...state,
        user: {...state.user, points: action.payload}
      };
    }

    case CLEAR_POINTS: {
      return {
        ...state,
        user: {...state.user, points: 0}
      };
    }
    default:
      return state;
  }
};
