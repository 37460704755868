import React, { useEffect, useState } from 'react'
import { 
    Button,
    Divider,
     Typography
} from '@mui/material';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useSelector } from 'react-redux';



export default function Trends({eventResults}) {

  return (
  <>
    <div className='trend-container'>
    <table id="tblBaccaratResultAll">
    <tbody>
    {eventResults.map((a, index) => {
        let newArray = [];
        let cols = 200;
        for(let i = 0; newArray.length <= cols; i++ ){

        if(index === 0){
          if(a[i]){
            newArray.push(a[i])
          } else {
            newArray.push(null)
          }
      } else {

        let ind = a.find(ab => ab.index === i);
        if(ind){
          newArray.push(ind)
        } else {
          newArray.push(null)
        }

      }
    }
      
        return (
          <tr className='' key={index}>
          {
              newArray.map((ab, index1) => {
                return (
                  <td className=' ' key={index1}>
                  {ab ?  
                    <div className={`v-avatar  trend-item rounded-0 trend-item ${ab && ab.win === 'meron' ? 'bg-meron' : ab.win === 'wala' ? 'bg-wala' : ab.win === 'draw' ? 'bg-draw' : 'bg-cancelled' }`}><Typography variant="caption" className='text-white'>{ab && ab.fightNo}</Typography></div>
                    :
                    <div className={`v-avatar  trend-item rounded-0 trend-item `}><Typography variant="caption" className='text-white'></Typography></div>
                  }
                  </td>
                )
              }) 
              }
             </tr>
        )  
      })}
      </tbody>
    </table>
    </div>
    </>
  )
}
