import * as React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Dialog, MenuItem, DialogTitle, DialogContent, IconButton, Typography, FormControl, TextField, Button, FormGroup, InputAdornment, FormLabel } from '@mui/material';
import axios from 'axios';


//Icons
import CloseIcon from '@mui/icons-material/Close';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { GCashAccounts } from '../../utils/dummyData';



//Components
import GcashCard from '../GcashCard';
import GCASHLOGO from '../../assets/gcash.jpg'
import { useDispatch, useSelector } from 'react-redux';
import { CLOSE_MODALS, SET_ERRORS } from '../../redux/actions/types';
import { addCredits } from '../../redux/actions/Users';
import { configs } from '../../utils/config';



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="small"
          sx={{
            position: 'absolute',
            right: 4,
            top: 4,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs() {
const dispatch = useDispatch();
  const { isWeb, modal }  = useSelector(({uiReducer}) => uiReducer);
  const { user } = useSelector(({auth}) => auth);

  const [values, setValues] = useState({
    amount: '',
    phone: user.phone,
    receiver: GCashAccounts[0].value,
    type: 'cashinRequest'
  });
  const [fileName, setFileName] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [error, setError] = useState(null);
    
  const handleFileChange = async (event) => {
    let file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);



    if(file){
      axios.post(configs.file_url + `/upload/${configs.uploadId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${configs.file_token}`
        },
      }).then(res => {
        setValues({...values, fileUrl: `${configs.file_url}/download/${res.data._id}`})
        setFileName(`${configs.file_url}/download/${res.data._id}`)
      })
      .catch(err => {
        setError('Something went wrong!')
      })
    }

}


  
  
const handleChanges = (prop) => event => {
  setValues({...values, [prop]: event.target.value})
  
}

  const handleClose = () => {
    dispatch({type: CLOSE_MODALS})
  };


const handleSubmit = (e) => {
    e.preventDefault();
    
    
    
    dispatch(addCredits(values))
    .then(() => {
      dispatch({type: CLOSE_MODALS});
    })
    .catch(err => {
    console.log(err.response, 'err')
        // setError(err.response.data.message)
      dispatch({type: SET_ERRORS, payload: { message: 'Submission Failed!' }})    
    })
    
    
    

}


  useEffect(() => {
  
  return () => {
    setFileName(null)
    setFileUrl(null)
    setValues({
      amount: '',
      phone: user.phone,
      receiver: GCashAccounts[0].value,
      type: 'cashinRequest'
    })
  }
  
  }, [modal, user])




  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={modal == 'cashinRequest'}
        maxWidth="sm"
        fullWidth={true}
        fullScreen={!isWeb}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} className="bg-dark-blue text-white text-15">
        &nbsp;&nbsp; ADD CREDITS
        </BootstrapDialogTitle>
        <DialogContent dividers 
        className="dialog-content text-white p-3"
        >
        
        <form onSubmit={handleSubmit} >
        
        
        {!fileName ? 
         
        <>
          <Typography className='text-15 dialog-content-title mt-3'>TRANSACTION TYPE</Typography>      
          <Typography className='text-15 text-bold mt-2 pl-2 text-yellow'>DEPOSIT</Typography>      
          <div className='gcash-wrapper'>
            <img src={GCASHLOGO} width="64px" className='gcash-img'/>
          </div>
          <GcashCard />
          <br/>
          </>
          :
          <>
          <span className="clickable" onClick={() => {
            setFileName(null)
          }}>Back</span>
          <div>
          <img src={fileName} height="300px" width="100%" />
          </div>
          </>
          }
          <div className='gcash-form bg-gcash-form p-3' >
            {!fileName ? 
            <>
            <span className='text-white text-center'>Screenshot of the GCash Receipt</span>
            <Button color="primary" aria-label="upload picture" component="label" className='w-100'>
              <input hidden accept="image/*" type="file" onChange={handleFileChange} />
              <div className='d-flex gcash-form-control'>
                <AttachFileIcon  className="text-white"/>
                <div className='gcash-form-input'><span className='text-15'>Choose Receipt</span></div>
                </div>
            </Button>
            </>
            :
            <>
               <Button color="primary" aria-label="upload picture" component="label"  className='text-bold w-100' >
              <input hidden accept="image/*" type="file" onChange={handleFileChange} />
              <AttachFileIcon  className="text-white"/>
                CHANGE IMAGE FILE
              {/* <div className='d-flex gcash-form-control'>
               
                <div className='gcash-form-input'><span className='text-15'>Choose Receipt</span></div>
                </div> */}
            </Button>
            <FormControl component="fieldset">
       <FormLabel component="legend" className='text-light input-label mt-2'>Amount To Load</FormLabel>
        <FormGroup aria-label="position" row>
            <TextField
                id="outlined-size-small"
                size="small"
                fullWidth
                placeholder="Ex: 100"
                className='login-input'
                value={values.amount}
                type="number"
                onChange={handleChanges('amount')}
              />
        </FormGroup>
       <FormLabel component="legend" className='text-light input-label mt-3'>Sender Number <span className='text-yellow text-12'>***Your Gcash Number</span></FormLabel>
        <FormGroup aria-label="position" row>
            <TextField
                id="outlined-size-small"
                size="small"
                placeholder="Ex: 09xxxxxxxxx"
                fullWidth
                className='login-input'
                value={values.sender}
                onChange={handleChanges('sender')}
              />
        </FormGroup>
        <FormLabel component="legend" className='text-light input-label mt-3'>Receiver Number</FormLabel>
        <FormGroup aria-label="position" row>
            <TextField
                id="outlined-size-small"
                size="small"
                fullWidth
                select
                className='login-input'
                value={values.receiver}
                onChange={handleChanges('receiver')}
              >
              {GCashAccounts.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.number} {option.name}
                </MenuItem>
              ))}
              </TextField>
        </FormGroup>
        <FormLabel component="legend" className='text-light input-label mt-3'>Ref # (Last 5 Digits)</FormLabel>
        <FormGroup aria-label="position" row>
            <TextField
                id="outlined-size-small"
                size="small"
                fullWidth
                className='login-input'
                placeholder="Ex: xxxxx"
                value={values.refNo}
                onChange={handleChanges('refNo')}
              />
        </FormGroup>
        <FormLabel component="legend" className='text-light input-label mt-3'>Notes</FormLabel>
        <FormGroup aria-label="position" row>
            <TextField
                id="outlined-size-small"
                size="small"
                fullWidth
                className='login-input'
                value={values.note}
                placeholder="More Details"
                onChange={handleChanges('note')}
              />
        </FormGroup>
    </FormControl>
    </>
    
    }
            
            
            <Button className='w-100 mt-2 text-bold' variant='contained'  
            disabled={!values.amount || !values.sender || !values.refNo}
            type="submit"
            >SUBMIT GCash P2P </Button>
          </div>
          </form>
          
        </DialogContent>
      
      </BootstrapDialog>
    </div>
  );
}
