import * as React from 'react';
import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { useDispatch, useSelector } from 'react-redux';
import { getAgentUsers } from '../redux/actions/Users';


export default function ReceiverInput({value, handleChanges,isWithdraw }) {
  const dispatch = useDispatch();
  const [options, setOptions] = useState([])
  

  const handleOptions = async () => {
   let opt = await dispatch(getAgentUsers());
   let newOpt = [];
   
   if(!isWithdraw){
    newOpt.push({
      title: 'Commission To Points',
      phone: 'Commission To Points'
     })
   }
 opt.forEach(a => {
    let newObj = {
      ...a,
      title: `0${String(a.phone)}-${String(a.username).toUpperCase()}`
    }
      newOpt.push(newObj)
   })
   setOptions(newOpt)
    
  }


useEffect(() => {
  handleOptions()
}, [])

  return (
    <Autocomplete
      id="highlights-demo"
      options={options}
      // value={value}
      onChange={(event, newValue) => {
        handleChanges(newValue);
      }}
      getOptionLabel={(option) => String(option.phone)}
      fullWidth
      renderInput={(params) => (
        <TextField {...params}  
        fullWidth
        className='login-input'
        id="outlined-size-small"
        />
      )}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option.title, inputValue, { insideWords: true });
        const parts = parse(option.title, matches);

        return (
          <li {...props}>
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </li>
        );
      }}
    />
  );
}
