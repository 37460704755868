import roleta from './roleta1.png';

import '../styles/Spinner.css';
import { useEffect, useState } from 'react';
import { SpinSlots } from '../utils/dummyData';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';

function randomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function App() {
  const { user } = useSelector(({auth}) => auth);
  const [spin, setSpin] = useState(0);
  const [rnd, setRnd] = useState(0);
  const [slots, setSlots] = useState([]);
  const [active, setActive] = useState(null);
  const [pos, setPos] = useState(0);


  const handleSpinStart = (val) => {
  
    if(val){

      let newSpin = (spin + (360 * randomInteger(10, 15))) + randomInteger(val.start, val.end)

    setSpin(360 * randomInteger(10, 15) + spin);
    setRnd(randomInteger(1, 5));
        setPos(newSpin)
        setActive(val.slot - 1)
      } else {
        let newSpin = (spin + (360 * randomInteger(10, 15))) + randomInteger(1, 360)

        setSpin(360 * randomInteger(10, 15) + spin);
        setRnd(randomInteger(1, 5));
            setPos(newSpin)
            setActive(null)
      }


  }



  const saveSlots = () => {
      localStorage.setItem('slots', JSON.stringify(slots));
  }

  const addSlots = (id, val) => {


      let slot = slots.find(a => a.slot === (id + 1));
    if(slot.start && slot.end){
      slot.start = null;
      slot.end = null;
    }

      if(slot.start){
         slot.end = val;     
      } else {
        slot.start = val ? val : 1;
        slot.end = null;
      }

     
      slots[id] = slot;

      setSlots(slots);
      setRnd(Math.random())
}



  useEffect(() => {
    let oldSlots = SpinSlots;
    if(oldSlots){
        setSlots(oldSlots);
    } else {
      let arr = new Array(25).fill({start: null, end: null})
      let newArr = [];
      for(let val in arr){
        let newObj = {
          ...arr[val],
          slot: Number(val) + 1
        }
        newArr.push(newObj)
      }

      setSlots(newArr)
    }

  }, [])


  const buttons = slots.map((a, index) => {
    return (
      <div key={index} style={{display: 'flex'}}>
        <button onClick={() => addSlots(index, pos)} >#{index + 1}</button>&nbsp;&nbsp;
        <small>START: {a.start}</small> - <small>END: {a.end}</small>&nbsp;&nbsp;
        {a.start && a.end && <button className={active === index ? 'active-btn' : ""} onClick={() => handleSpinStart(a)} >START</button>}
      </div>
    )
  })


  // console.log(pos)
  // console.log(slots)
  return (
    <>
          <h2 style={{color: 'goldenrod', textAlign: 'center'}}>SPIN IT TO WIN IT</h2>
    
        <br/>
        <div className='stand' >
        {/* <div style={{text
        : 'center', textAlign: 'center', marginBottom: '-15px', fontWeight: 'bolder', color: 'gold'}}>I</div> */}
        <div className={spin ? "container" : ""}  style={{ zIndex: -1, transform: `rotate(${pos}deg)`}}>
  <img src={roleta} className={spin ? "logo" : ""} height="250px" width="250px"></img>
</div>
</div>
<br/>
<div style={{display: 'flex', justifyContent: 'center', width: '300px'}}>
        {/* <button onClick={() => handleMove('')}>{`<<`}</button> */}
        <Button variant='contained'  onClick={() => handleSpinStart()}>SPIN</Button>

        {/* <button onClick={() => handleMove('next')}>{`>>`}</button> */}
        </div>
<br/>
{user && user.role === 'admin' && buttons}
          <br/>
          {/* <button onClick={() => saveSlots()}>SAVE</button> */}
          </>
  );
}

export default App;
