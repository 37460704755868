// encryption.js
import utf8Enc from "crypto-js/enc-utf8";
import aes from "crypto-js/aes";


const secret_key = 'bugtech', secret_iv = 'secretIV', ecnryption_method = 'aes-256-cbc';


if (!secret_key || !secret_iv || !ecnryption_method) {
  throw new Error('secretKey, secretIV, and ecnryptionMethod are required')
}

// Encrypt data
export function encryptData(data) {
 let encrypted = aes.encrypt(JSON.stringify(data), secret_key).toString();
return encrypted;
}

// Decrypt data
export function decryptData(encryptedData) {
    const decrypted = aes.decrypt(encryptedData, secret_key).toString(utf8Enc);
return JSON.parse(decrypted)
}
