// PRod
  // const host = 'https://swc.sharewin.pro';
  // const url = `${host}/api`;
  // const apiUrl = `${url}/v1`;
  // // const streamUrl = `${host}:8000/live`;
  // const streamUrl = `https://sharewin.pro/live`;
  // const staticUrl = `${url}/static`;
  // const assetsUrl = `${url}/assets`;
  // const webUrl = `https://swc.sharewin.pro`;
  // const socketUrl = `${host}`;

// Dev
const host = 'https://004.bugtech.online';
const url = `${host}/api`;
const  apiUrl = `${url}/v1`;
//const streamUrl = `${host}/live`;
const streamUrl = `https://sharewin.pro/live`;
const staticUrl = `${url}/static`;
const assetsUrl = `${url}/assets`;
const webUrl = `${host}`;
const socketUrl = `${host}`;



// // //Local 
// const host = 'http://192.168.1.125';
// const url = `${host}:3001`;
// const apiUrl = `${url}/api/v1`;
// const staticUrl = `${url}/static`;
// const assetsUrl = `${url}/assets`;
// const webUrl = `${host}:3000`;

// // //Local
// const host = 'http://localhost';
// const url = `${host}:3005/api`;
// const apiUrl = `${url}/v1`;
// const streamUrl = `http://localhost:8000/live`;
// const staticUrl = `${url}/static`;
// const assetsUrl = `${url}/assets`;
// const webUrl = `${host}:3000`;
// const socketUrl = `api.aos777.live`;


// // //Test
// const url = 'http://localhost:5301';
// const apiUrl = `${url}/api`;
// const staticUrl = `${apiUrl}/static/`;

export default {
  hostUrl: url,
  apiUrl,
  staticUrl,
  assetsUrl,
  webUrl,
  socketUrl,
  streamUrl
};
