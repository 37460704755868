import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Dialog, Box, DialogTitle, DialogContent, IconButton, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';



//Icons
import CloseIcon from '@mui/icons-material/Close';
import MessageIcon from '@mui/icons-material/Message';
import { useDispatch, useSelector } from 'react-redux';
import { CLOSE_MODALS } from '../../redux/actions/types';


const rulesBetting = ['FIGHT WITH 107 AND BELLOW PAYOUT, SHALL BE CANCELLED.'];

const rulesOnLoad = ['CFC IS NOT RESPONSIBLE FOR YOUR CASH-IN/CASH-OUT USING DIFFERENT ACCOUNT NUMBERS.', 'WE CAN ONLY CATER TO THOSE CASH-IN/CASH-OUT UNDER OUR ACCOUNT NUMBERS.', ' WE ARE NOT RESPONSIBLE TO ANY AGREEMENT YOU MADE WITH YOUR COORDINATION.']


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="small"
          sx={{
            position: 'absolute',
            right: 4,
            top: 4,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs() {
  const { isWeb, rulesModal }  = useSelector(({uiReducer}) => uiReducer);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch({type: CLOSE_MODALS})
  };


  return (
    <div>
      <BootstrapDialog
        onClose={() => handleClose()}
        aria-labelledby="customized-dialog-title"
        open={rulesModal}
        // maxWidth="md"
        fullScreen={!isWeb ? true : false}
      >
        <BootstrapDialogTitle id="customized-dialog-title"   onClose={() => handleClose()} className="bg-dark-blue text-white text-15">
        &nbsp;&nbsp;HELP
        </BootstrapDialogTitle>
        <DialogContent dividers 
        className="dialog-content text-white p-4"
        >
          <Typography className='text-15 text-bold'>RULES ON BETTING</Typography>      
          <ol className='m-3'>
          {rulesBetting.map((a, index) => {
          return (
            <li className='mb-3' key={index}>
            <Typography variant='body1' className="text-12">
            {a}
          </Typography>
            </li>
          )
          })}
          
          </ol>
          <br/>
          <Typography className='text-15 text-bold'>RULES ON LOAD</Typography>      
          <ol className='m-3'>
          {rulesOnLoad.map((a, index) => {
          return (
            <li className='mt-4 mb-4' key={index}>
            <Typography variant='body1' className="text-12">
            {a}
          </Typography>
            </li>
          )
          })}
          
          </ol>
          <br/>
          <Box className='user-profile'>
          <div className='user-actions'>
          <button className='btn-contact p-1 text-sm-rem  ml-3 mr-3'  disabled style={{opacity: 0.5}}><MessageIcon/> <strong>CONTACT CUSTOMER SUPPORT</strong></button>
          </div>
          </Box>
        </DialogContent>
      
      </BootstrapDialog>
    </div>
  );
}
