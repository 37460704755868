import * as React from 'react';
import { useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';



import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Main from './Main';
import Sidebar from './Sidebar';

import { useDispatch, useSelector } from 'react-redux';
import {   SET_MEDIA_WIDTH, SET_BETS_HISTORY } from '../../redux/actions/types';

//Components
import BottomNavigation from '../../components/BottomNavigation';
import Rules from '../../components/Dialog/Rules';
import AddPoints from '../../components/Dialog/AddPoints';
import AddLoad from '../../components/Dialog/AddLoad';
import RulesCard from '../../components/RulesCard'

import SettingsModal from '../../components/Dialog/Settings';
import PromoModal from '../../components/Dialog/Promo';
import ChatSupport from '../../components/Dialog/ChatSupport';
import TransactionsModal from '../../components/Dialog/Transactions'
import CashRequestsModal from '../../components/Dialog/CashRequests'
import BetModal from '../../components/Dialog/Bet'
import SpinnerModal from '../../components/Dialog/SpinnerModal'
import Alert from '../../components/Alert';
import PlayerLists from '../../components/Dialog/PlayerLists';
import CommissionSlider from '../../components/Dialog/CommissionSlider';
import CountNumber from '../../components/Dialog/CountNumber';
import AdvanceBets from '../../components/Dialog/AdvanceBets';
import CashInRequestsModal from '../../components/Dialog/CashInRequests'
import CashRequestsList from '../../components/Dialog/CashRequestList';
import MembersList from '../../components/Dialog/MembersList';
import { getBets } from '../../redux/actions/Data';
import { disconnectSocket } from '../../utils/socket.service';




const theme = createTheme();

export default function Blog() {
  const dispatch = useDispatch();
  const { activeTab} = useSelector(({uiReducer}) => uiReducer);

  const isWeb = useMediaQuery('(min-width:900px)');




useEffect(() => {
    dispatch({type: SET_MEDIA_WIDTH, payload: isWeb})
}, [isWeb])


useEffect(() => {

return () => {
    disconnectSocket()
}
}, [])



  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Alert/>
      <Rules />
      <AddPoints/> 
      <CashInRequestsModal/>
      <CommissionSlider/>
      <AddLoad/> 
      <SettingsModal/>
      <PromoModal/>
      <ChatSupport/>
      <TransactionsModal/>
      <CashRequestsModal/>
      <CashRequestsList/>
      <BetModal/>
      
      <SpinnerModal/>
      <PlayerLists/>
      <MembersList/>
      <AdvanceBets/>
      {!isWeb ? <>
        {(!isWeb && activeTab === 0) && <RulesCard/>}
        {(!isWeb && activeTab === 1) && <Main />}
        {(!isWeb && activeTab === 2)  && <><Sidebar/></>}     
        {!isWeb &&   <div className='btn-nav'>
          <BottomNavigation/>
          </div>}
      </>
      :
      <>
      <Container maxWidth="lg">
        {isWeb && <br/>}
        <main className='main-wrapper'>
          <Grid container spacing={3} className='app-main-container'>
          {(isWeb && activeTab === 1) &&  
             <Grid
             item
             xs={12}
             md={8}
             className='main-wrap'
           >
          <Main />
          </Grid>
          }
            {isWeb  && 
         <Grid item xs={12} md={4} >
            <Sidebar/>
            </Grid>
            }
          </Grid>
        </main>
      </Container>  
     
      </>
      }

      
 
      
 
    </ThemeProvider>
  );
}