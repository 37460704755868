import * as React from 'react';
import { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Dialog, Button, Box, DialogTitle, DialogContent, FormControl, FormLabel, FormGroup, TextField,  IconButton, Typography, InputAdornment} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';



//Icons
import CloseIcon from '@mui/icons-material/Close';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';






import { useDispatch, useSelector } from 'react-redux';
import { CLOSE_MODALS, CLEAR_ERRORS } from '../../redux/actions/types';
import { clearBetsHistory, updatePassword, updateUsername } from '../../redux/actions/Users';
import { getUserData } from '../../redux/actions/Auth';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="small"
          sx={{
            position: 'absolute',
            right: 4,
            top: 4,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs() {
  const dispatch = useDispatch();
  const { betsHistory} = useSelector(({usersReducer}) => usersReducer);
  const { isWeb, settingsModal, errors }  = useSelector(({uiReducer}) => uiReducer);
  const { user } = useSelector(({auth}) => auth);
    const [values, setValues] = useState({
      username: ''
    });
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = React.useState({currentPassword: false, password: false, confirmPassword: false});
    const handleClickShowPassword = (prop) => setShowPassword({...showPassword, [prop]: !showPassword[prop]});

  const handleClose = () => {
    dispatch({type: CLOSE_MODALS})
  };
  
  const handleChanges = (prop) => event => {
    
    setValues({...values, [prop]: event.target.value})
  
  }
  
  
  const handleClearBets = () => {
    dispatch(clearBetsHistory())
  }
  
  const handleUpdatePassword = () => {
    dispatch({type: CLEAR_ERRORS})

    setLoading(true)
    dispatch(updatePassword(values))
    .then(() => {
      setLoading(false)  
      dispatch(getUserData())
      setValues({currentPassword: '', password: '', confirmPassword: ''})
    })
    .catch(err => {
      setLoading(false)  
    })
  }
  
  
    
  const handleUpdateUsername = () => {
  dispatch({type: CLEAR_ERRORS})
  setLoading(true)
    dispatch(updateUsername(values))
    .then(() => {
          setLoading(false)  
          dispatch(getUserData())
        

    })
    .catch(err => {
      setLoading(false)  
    })
  }
  
  useEffect(() => {
  
    if(user){
      setValues({...values, username: user.username})
    }
    
    return () => {
        setValues({username: ''})
    }
  
  }, [user])
  


  return (
    <div>
      <BootstrapDialog
        onClose={() => handleClose()}
        aria-labelledby="customized-dialog-title"
        open={settingsModal}
        maxWidth="sm"
        fullWidth
        fullScreen={!isWeb ? true : false}
      >
        <BootstrapDialogTitle id="customized-dialog-title"   onClose={() => handleClose()} className="bg-dark-blue text-white text-15">
        &nbsp;&nbsp;SETTINGS
        </BootstrapDialogTitle>
        <DialogContent dividers 
        className="dialog-content text-white p-5"
        >
        <Typography>UPDATE NAME</Typography>
       <FormLabel component="legend" className='text-light input-label mt-3'>Name</FormLabel>
        <FormGroup aria-label="position" row>
            <TextField
                id="outlined-size-small"
                size="small"
                fullWidth
                className='login-input'
                value={values.username}
                onChange={handleChanges('username')}
                error={errors.username}
                helperText={errors.username && errors.username}
              />
        </FormGroup>
        <Button disabled={loading} className='w-100 mt-2 mb-2text-bold' variant='contained' onClick={() => handleUpdateUsername()}>UPDATE</Button>
        <br/>
        <br/>
        <Typography >UPDATE PASSWORD</Typography>
      <FormLabel component="legend" className='mt-3 text-light input-label'>Current Password</FormLabel>
      <FormGroup aria-label="position" row>
      <TextField
          id="outlined-size-small"
          value={values.currentPassword}
          onChange={handleChanges('currentPassword')}
          error={errors.currentPassword}
          helperText={errors.currentPassword && errors.currentPassword}
          size="small"
          fullWidth
          className='login-input'
          type={showPassword.currentPassword ? "text" : "password"}
          
          InputProps={{
            endAdornment:  <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => handleClickShowPassword('currentPassword')}
              edge="end"
              className='text-light'
            >
              {showPassword.currentPassword ?  <Visibility /> : <VisibilityOff /> }
            </IconButton>
          </InputAdornment>,
          }}
         
        />
      </FormGroup>
      <FormLabel component="legend" className='mt-1 text-light input-label'>Password</FormLabel>
      <FormGroup aria-label="position" row>
      <TextField
          id="outlined-size-small"
          value={values.password}
          onChange={handleChanges('password')}
          size="small"
          fullWidth
          className='login-input'
          type={showPassword.password ? "text" : "password"}
          error={errors.password}
          helperText={errors.password && errors.password}
          InputProps={{
            endAdornment:  <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => handleClickShowPassword('password')}
              edge="end"
              className='text-light'
            >
              {showPassword.password ?  <Visibility /> : <VisibilityOff /> }
            </IconButton>
          </InputAdornment>,
          }}
         
        />
      </FormGroup>
      <FormLabel component="legend" className='mt-1 text-light input-label'>Repeat New Password</FormLabel>
      <FormGroup aria-label="position" row>
      <TextField
          id="outlined-size-small"
          value={values.confirmPassword}
          onChange={handleChanges('confirmPassword')}
          size="small"
          fullWidth
          error={errors.confirmPassword}
          helperText={errors.confirmPassword && errors.confirmPassword}
          className='login-input'
          type={showPassword.confirmPassword ? "text" : "password"}
          
          InputProps={{
            endAdornment:  <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => handleClickShowPassword('confirmPassword')}
              edge="end"
              className='text-light'
            >
              {showPassword.confirmPassword ?  <Visibility /> : <VisibilityOff /> }
            </IconButton>
          </InputAdornment>,
          }}
         
        />
      </FormGroup>
      <Button className='w-100 mt-2 mb-2text-bold' variant='contained' disabled={loading} onClick={() => handleUpdatePassword()}>UPDATE</Button>
      <br/><br/>
      <Typography>BETS</Typography>
      <Button className='w-100 mt-2 mb-2text-bold' variant='contained' onClick={handleClearBets} disabled={betsHistory.length === 0}>CLEAR BETS HISTORY</Button>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
