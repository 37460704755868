import * as React from 'react';
import { useState, useEffect } from 'react';
// import Markdown from './Markdown';
import { 
     Typography, Divider, Button
} from '@mui/material';


//Components
import Trends from '../../components/Trends';
import LivePlayer from '../../components/LivePlayer';
import Bettings from '../../components/Bettings';
import BetsHistory from '../../components/BetsHistory';
import { useSelector, useDispatch } from 'react-redux';
import { CLEAR_BETS, SET_BETS, SET_MODAL_POINTS,CLEAR_BET, SET_FIGHT, SET_RESULTS, SET_STREAMING, SET_MODAL } from '../../redux/actions/types';
import useIsTabActive from '../useActiveTab';
import PlayBtn from '../../assets/playbutton.png';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AccessTimeIcon from '@mui/icons-material/AccessTime';


//SOCKET
import {
  subscribeToEvent,
  subscribeToBetting,
  initiateSocketConnection,
  disconnectSocket
} from "../../utils/socket.service";
import '../../App.css';
import { getUserData } from '../../redux/actions/Auth';



function Main() {
  const dispatch = useDispatch();
  const isTabActive = useIsTabActive();
  const { currentFight, bet} = useSelector(({betting}) => betting);

  const stream = useSelector(({stream}) => stream);
  const { isWeb, errors }  = useSelector(({uiReducer}) => uiReducer);
  const { user } = useSelector(({auth}) => auth);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isBanner, setIsBanner] = useState(false);
  const [result, setResult] = useState({
    fightNo: 0,
    win: '',
  });
  
  
  
  const handlePlay = () => {
    if(currentFight.fightStatus != 'standby'){
      setIsPlaying(true)
      dispatch({type: SET_STREAMING, payload: {isPlaying: true}})
    }
  }


  
  useEffect(() => {

    const token = localStorage.idToken;

if(token){
    initiateSocketConnection(token);
      subscribeToEvent((err, val) => {
          let { fightNo, fightStatus, resultCounts, event, entry, win} = val; 
         dispatch({type: SET_FIGHT, payload: {...currentFight, event, entry, fightStatus: fightStatus, fightNo, win }})
         if(resultCounts){
          dispatch({type: SET_RESULTS, payload: resultCounts})
        }
        console.clear()
        })
        subscribeToBetting((err, val) => {
          if(val){
            dispatch({type: SET_BETS, payload: val})
          }
        })
      }
      return () => {
        disconnectSocket();
    }
  }, []);
  

  
useEffect(() => {
  // console.clear()
  if( currentFight && currentFight.fightStatus === 'open'){
    setIsBanner(false)
    return;
  } else if( currentFight && currentFight.fightStatus === 'standby'){
    dispatch({type: SET_STREAMING, payload: {isPlaying: false}})
    setIsPlaying(false)
    setIsBanner(true);
    setResult({fightNo: 0, win: 'standby'})
    return;
  } else if (!isBanner && currentFight && currentFight.fightStatus === 'end'){
    setIsBanner(true);
    setResult({fightNo: currentFight.fightNo, win: currentFight.win})
    setTimeout(() => {
    dispatch({type: CLEAR_BETS })
    dispatch({type: CLEAR_BET })
      setResult({fightNo: currentFight.fightNo, win: ''})
      dispatch(getUserData())
      // setIsBanner(false);
    }, 10000)
  } else {
    setIsBanner(false)
    setResult({fightNo: currentFight.fightNo, win: currentFight.win})
  }
}, [currentFight.fightStatus])


useEffect(() => {
  if(!isTabActive){
        dispatch({type: SET_STREAMING, payload: {isPlaying: false}})
        setIsPlaying(false)
  }
}, [isTabActive])

let {event, entry} = currentFight;

let canView = (bet.meron || bet.wala || bet.draw);


  return (
 <>
      <div className='app-main'>
        {/* //HEADER */}
        <div className='app-header-wrapper'>
        <div className={`app-header-left ${ currentFight.fightStatus === 'open' ? 'h-lf-open'  : isBanner ? result.win === "meron" ? "h-lf-meron" : result.win === 'wala' ? "h-lf-wala" : result.win === "draw" ? "h-lf-draw" : "h-lf-cancelled" : "h-lf-close"}`}>
        <div className='header-text-wrapper '>
        {currentFight.fightStatus === 'lastCall' ? 'LAST CALL' : currentFight.fightStatus === 'open' ? 'OPEN' : currentFight.fightStatus === 'standby' ? 'STANDBY' :  isBanner ? 'FINISHED' :  'CLOSE'}
        </div>
        </div>
        <div className={`app-header-center h-cr-meron ${currentFight.fightStatus === 'open' ? 'h-cr-open' : isBanner ? result.win === "meron" ? "h-cr-meron" : result.win === 'wala' ? "h-cr-wala" : result.win === "draw" ? "h-cr-draw" : "h-cr-cancelled"  : 'h-cr-close'}`}>
        <div className='p-1'>
            <strong className='header-title'>ULTIMATE</strong>
            <div className='header-sub'>FIGHTING COCK</div>
            </div>
        </div>
        <div className={`app-header-right ${ currentFight.fightStatus === 'open' ? 'h-rh-open' : isBanner ? result.win === "meron" ? "h-rh-meron" : result.win === 'wala' ? "h-rh-wala" : result.win === "draw" ? "h-rh-draw" : "h-rh-cancelled"  : 'h-rh-close'}`}>
        <div className='header-text-wrapper '>
        FIGHT #{isBanner ? result.fightNo : currentFight.fightNo}
        </div>
        </div>
        </div>
          
      </div>
         {/* DESCRIPTIONER */}
         <div className="descriptioner  d-flex" style={{width: '100%', zIndex: 1000}} >
         <div className="trend-side-header web-view text-yellow font-bold" style={{height: '35px', width: '25%', justifyContent: 'center', alignItems: 'center'}}>
          SWC
         </div>
         <div className='descriptioner text-white' style={{width: '100%', height: '35px',zIndex: 0}}>
         {/* {moment().format('llll')  } */}
         <p className="words mt-2 text-12">{event && event.name}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{event && event.date_open}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{entry && entry.announcement}</p>
          </div>
          <div className="trend-side-header web-view" style={{height: '35px', width: '25%'}}>
          <div className="font-monospace clickable" 
          onClick={() => window.location.reload()}
          style={{ paddingRight: '3px', paddingLeft: '2px', height: '35px', width: '100%', zIndex: 100, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <AutorenewIcon style={{fontSize: '12px', marginRight: '2px'}} /><small>Refresh</small>
          </div>
          </div>
         </div>

          <div className='video-player'>
           <div className='overlay-play-button'> 
         {((user.points >= 10) || canView) ? isPlaying ? <LivePlayer
          // url={`${commonData.streamUrl}/stream/live`}
          stream={stream}
           /> : 
           <div className="overlay-play-button__play" onClick={() => handlePlay()}>
           <img src={PlayBtn} height="100%" width="100%" />
          </div>
           : 
           <p className="text-white text-center text-12">You should have atleast 100 points to view the video.</p>
           }
           </div> 
      </div>

          
         {!isBanner || !result.win ? 
          <Bettings />
        :
        <div className={`result-banner ${result.win === "meron" ? "bg-meron" : result.win === "wala" ? "bg-wala" : result.win === "draw" ? "bg-draw" : "bg-cancelled"}`}>
        <div>
         {result.win !== 'standby' && <span className='text-white'>FIGHT #{result.fightNo} RESULT</span>}<br/>
          <h4 className='text-bold text-white'>{result.win === "wala" ? "WALA WINS" : result.win ===  "meron" ? "MERON WINS" : result.win === "draw" ? "MATCH DRAW" : result.win == 'standby' ? "No Available Event" : "MATCH CANCELLED"}</h4>
          </div>
        </div>
          }
     <div className='main-trends'>
         <div className={errors.limit ? `bg-danger trend-header trend-header-caption text-white text-bold` : `trend-header trend-header-caption`} >
          DRAW WINS x 8. Max bet per player: 5000/fight.
        </div>
        {!isWeb &&
          <div className='current-points p-2 mt-1 mb-1'>
                <div> <Typography variant="body1" className='text-white text-15'>CURRENT POINTS:&nbsp;<strong className='text-yellow text-15'>{user.points.toLocaleString("en-US")}</strong></Typography></div>
                {user.role == 'agent' ?
                <Button size='small' variant="contained" color="success" className='h-20 mb-1' onClick={() => dispatch({type: SET_MODAL_POINTS})}>ADD POINTS</Button>
                :
                <Button size='small' variant="contained" color="success" className='h-20 mb-1' onClick={() => dispatch({type: SET_MODAL, payload: 'cashinRequest'})}>ADD POINTS</Button>
                }
                
                </div>
          }
    <div className='trend-content web-view p-3'>
        <Trends/>
        <div className='advance-bets-wrapper mt-3'>
         <Typography variant='subtitle2' className='text-white'>BETS</Typography>
        <Button size='small'  className='advance-bet' onClick={() => {
          dispatch({type: SET_MODAL, payload: 'advanceBets'})
        }}><AccessTimeIcon/>&nbsp;ADVANCE BETS</Button> 
        </div>
        <Divider className='mt-1 mb-3 divider-bot' />
         <BetsHistory/>
      </div>
  </div>
        </>
  );
}


export default Main;