//Expected Actions to be used throughout the lifecycle of the app.

//AUTH
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SET_USER = 'SET_USER';
export const SET_STORE = 'SET_STORE';
export const UPDATE_AUTH_USER = 'UPDATE_AUTH_USER';
export const UPDATE_LOAD_USER = 'UPDATE_LOAD_USER';
export const CLEAR_USER = 'CLEAR_USER';
export const SEND_FORGET_PASSWORD_EMAIL = 'SEND_FORGET_PASSWORD_EMAIL';

//UI
export const SET_LOADING = 'SET_LOADING';
export const STOP_LOADING = 'STOP_LOADING';
export const SET_ERRORS = 'SET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_SELECTED = 'SET_SELECTED';
export const SET_UNSELECTED = 'SET_UNSELECTED';
export const SET_MEDIA_WIDTH = 'SET_MEDIA_WIDTH';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const SET_MODAL_RULES = 'SET_MODAL_RULES';
export const SET_MODAL_POINTS = 'SET_MODAL_POINTS';
export const SET_MODAL_SETTINGS = 'SET_MODAL_SETTINGS';
export const SET_MODAL_PROMO = 'SET_MODAL_PROMO';
export const SET_MODAL_SUPPORT = 'SET_MODAL_SUPPORT';
export const SET_MODAL_TRANSACTIONS = 'SET_MODAL_TRANSACTIONS';
export const SET_MODAL_WITHDRAWAL = 'SET_MODAL_WITHDRAWAL';
export const SET_MODAL_CASH_REQUESTS = 'SET_MODAL_CASH_REQUESTS';
export const SET_MODAL_BET = 'SET_MODAL_BET';
export const SET_MODAL_USERS = 'SET_MODAL_USERS';
export const SET_MODAL = 'SET_MODAL';
export const CLOSE_MODALS = 'CLOSE_MODALS';
export const SET_SNACKBAR = 'SET_SNACKBAR';
export const CLEAR_SNACKBAR = 'CLEAR_SNACKBAR';
export const SET_MODAL_SPIN = 'SET_MODAL_SPIN';


//DASHBOARD
export const SET_DASHBOARD_DATA = 'SET_DASHBOARD_DATA';
export const CLEAR_DASHBOARD_DATA = 'CLEAR_DASHBOARD_DATA';

export const SET_EVENT_FORM = 'SET_EVENT_FORM';
export const CLEAR_EVENT_FORM = 'CLEAR_EVENT_FORM';

export const SET_EVENT = 'SET_EVENT';
export const CLEAR_EVENT = 'CLEAR_EVENT';

export const SET_EVENTS = 'SET_EVENTS';
export const SET_MATCHES = 'SET_MATCHES';
export const SET_MATCH = 'SET_MATCH';
export const CLEAR_MATCH = 'CLEAR_MATCH';

export const CLEAR_EVENTS = 'CLEAR_EVENTS';
export const CLEAR_MATCHES = 'CLEAR_MATCHES';


//Data
export const SET_TRANSACTIONS = 'SET_TRANSACTIONS';
export const CLEAR_TRANSACTIONS = 'CLEAR_TRANSACTIONS';
export const SET_CASH_REQUESTS = 'SET_CASH_REQUESTS';
export const CLEAR_CASH_REQUESTS = 'CLEAR_CASH_REQUESTS';
export const SET_ACCOUNTS = "SET_ACCOUNTS";
export const SET_RESULTS = 'SET_RESULTS';
export const SET_SEGMENT = 'SET_SEGMENT';
export const SET_SEGMENTS = 'SET_SEGMENTS';
export const CLEAR_SEGMENT = 'CLEAR_SEGMENT';
export const CLEAR_SEGMENTS = 'CLEAR_SEGMENTS';
export const SET_POINTS = 'SET_POINTS';
export const CLEAR_POINTS = 'CLEAR_POINTS';
export const SET_TRANSACTION_DATA = 'SET_TRANSACTION_DATA';
export const CLEAR_TRANSACTION_DATA = 'CLEAR_TRANSACTION_DATA';


//STREAMING
export const SET_SOCKET_ID = 'SET_SOCKET_ID';
export const CLEAR_SOCKET = 'CLEAR_SOCKET';
export const SET_BALANCE = 'SET_BALANCE';
export const STOP_BALANCE = 'STOP_BALANCE';
export const SET_STREAM = 'SET_STREAM';
export const STOP_STREAM = 'STOP_STREAM';
export const SET_STREAMING = 'SET_STREAMING';
export const STOP_STREAMING = 'STOP_STREAMING';
export const SET_TIMELAPSE = 'SET_TIMELAPSE';
export const SET_FIGHT = 'SET_FIGHT';
export const CLEAR_FIGHT = 'CLEAR_FIGHT';
export const SET_BET = 'SET_BET';
export const CLEAR_BET = 'CLEAR_BET';
export const SET_BETS = 'SET_BETS';
export const CLEAR_BETS = 'CLEAR_BETS';
export const SET_ODDS = 'SET_ODDS';
export const CLEAR_ODDS = 'CLEAR_ODDS';
export const SET_ADVANCE_BETS = 'SET_ADVANCE_BETS';
export const CLEAR_ADVANCE_BETS = 'CLEAR_ADVANCE_BETS';

export const SET_BETS_HISTORY = 'SET_BETS_HISTORY';
export const CLEAR_BETS_HISTORY = 'CLEAR_BETS_HISTORY';


export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const CREATE_STREAM = 'CREATE_STREAM';
export const FETCH_STREAMS = 'FETCH_STREAMS';
export const FETCH_STREAM = 'FETCH_STREAM';
export const DELETE_STREAM = 'DELETE_STREAM';
export const EDIT_STREAM = 'EDIT_STREAM';
export const SET_STREAM_URL = 'SET_STREAM_URL';
export const CLEAR_STREAM_URL = 'CLEAR_STREAM_URL';
