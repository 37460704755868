import {  CLEAR_BET, SET_BET, SET_BETS, SET_ADVANCE_BETS, CLEAR_ADVANCE_BETS, SET_ODDS, CLEAR_ODDS, SET_FIGHT, SET_RESULTS, CLEAR_BETS } from "../actions/types";

const INIT_BETS = {
  meron: 0,
  wala: 0,
  draw: 0,
  meronRate: 0,
  walaRate: 0,
  drawRate: 8
}

const INIT_BET = {
  meron: 0,
  wala: 0,
  draw: 0,
  drawRate: 0,
  meronRate: 0,
  walaRate: 0,
  fightNo: 0,
  event: null,
}

const INIT_ODDS = {
  meron: 0, 
  wala: 0,
  draw: 0
}


const INIT_FIGHT = {
        fightNo: 0,
        win: 'standby',
        fightStatus: 'standby',
        event: {},
        entry: {}
}

const INIT_RESULTS = {
  meron: 0,
  wala: 0,
  draw: 0,
  cancelled: 0,
  results: []
}



const INIT_STATE = {
    currentFight: INIT_FIGHT,
    bet: INIT_BET,
    bets: INIT_BETS,
    odds: INIT_ODDS,
    eventResults: INIT_RESULTS,
    advanceBets: []
};

export default (state = INIT_STATE, action) => {
  const {type, payload} = action;

  switch (type) {
  

 
    case SET_RESULTS: {
      return {
        ...state,
        eventResults: payload
      };
    }


    case SET_FIGHT: {
      return {
        ...state,
        currentFight: {...state.currentFight, ...payload}
      };
    }

    case SET_BET: {
      return {
        ...state,
        bet: { ...state.bet, ...payload }
      };
    }

    case SET_BETS: {
      return {
        ...state,
        bets: {...state.bets, ...payload}
      };
    }

    case SET_ADVANCE_BETS: {
      return {
        ...state,
        advanceBets: payload
      };
    }
    


    case CLEAR_ADVANCE_BETS: {
      return {
        ...state,
        advanceBets: []
      };
    }
    
    
    case SET_ODDS: { 
      return {
        ...state,
        odds: payload
      };
    }

    case CLEAR_ODDS: {
      return {
        ...state,
        odds: INIT_ODDS
      };
    }

    case CLEAR_BET: {
      return {
        ...state,
        bet: INIT_BET
      };
    }

    case CLEAR_BETS: {
      return {
        ...state,
        bets: INIT_BETS
      };
    }

    default:
      return state;
  }
};
