import React, { useState, useEffect } from 'react'
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { IconButton, Popover, Typography } from '@mui/material';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import commonData from '../utils/commonData';



export default function CopyClipboard({refCode}) {
  const [values, setValues] = useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setTimeout(() => {
      handleClose()
    }, 5000)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


useEffect(() => {
  setValues(`${commonData.webUrl}/register/${refCode}`)
}, [refCode])


return (
  <>
  <CopyToClipboard text={values}  onCopy={() =>  setValues(`${commonData.webUrl}/register/${refCode}`)}
 >
    <IconButton aria-describedby={id} onClick={handleClick} >
      <GroupAddIcon className='help-btn'/>
    </IconButton>

</CopyToClipboard>
<Popover
id={id}
open={open}
anchorEl={anchorEl}
onClose={handleClose}
anchorOrigin={{
  vertical: 'bottom',
  horizontal: 'left',
}}
>
<Typography sx={{ p: 2 }}>Link copied to clipboard.</Typography>
</Popover>
</>
)
}