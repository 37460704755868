import axios from 'axios';
import { SEND_FORGET_PASSWORD_EMAIL, UPDATE_AUTH_USER, SET_BETS, SET_BET, UPDATE_LOAD_USER, CLEAR_USER, SET_ADVANCE_BETS, SET_ERRORS, CLEAR_ERRORS, SET_POINTS, SET_SEGMENT, SET_TIMELAPSE, SET_FIGHT, SET_RESULTS, SET_STREAM_URL, LOGIN } from './types';

import commonData from '../../utils/commonData';
import { authHeader } from '../auth-header';
import { decryptData } from '../../utils/encryption';
import { getBets } from './Data';


export const setAuthUser = user => {
  return dispatch => {
    dispatch({
      type: UPDATE_AUTH_USER,
      payload: user,
    });
  };
};

export const updateLoadUser = loading => {
  return dispatch => {
    dispatch({
      type: UPDATE_LOAD_USER,
      payload: loading,
    });
  };
};

export const setForgetPassMailSent = status => {
  return dispatch => {
    dispatch({
      type: SEND_FORGET_PASSWORD_EMAIL,
      payload: status,
    });
  };
};

export const registerUser = (user, rid) => dispatch => {
  dispatch({type: CLEAR_ERRORS})
  return axios
      .post(`${commonData.apiUrl}/auth/signup?rid=${rid}`, user)
      .then(({data}) => {
        // let { token } = data;
          // dispatch({type: UPDATE_AUTH_USER, payload: data })
          // localStorage.setItem('idToken', token);
          return window.location.href = '/login'
      })
      .catch(({response}) => {
        // dispatch()
        let {data } = response;
        if(data.d){
          dispatch({type: SET_ERRORS, payload: data.d})
        } else {
          dispatch({type: SET_ERRORS, payload: data})
        }
        // dispatch(fetchError(message.text));
      });
};

export const loginUser = (user, history) => dispatch => {
  dispatch({type: CLEAR_ERRORS})
  return axios
      .post(`${commonData.apiUrl}/auth/signin`, user)
      .then(({data}) => {

        let { token } = data;
          // dispatch({type: UPDATE_AUTH_USER, payload: data })
          localStorage.setItem('idToken', token);
          
          dispatch(getUserData(history));
          // history.push('/')
          // window.location.href = '/home'
      })
      .catch(({response}) => {
        // dispatch()
        let data = response ? response.data : null;
        if(data && data.d){
          dispatch({type: SET_ERRORS, payload: data.d})
        } else {
          dispatch({type: SET_ERRORS, payload: {message: data.message ? data.message : 'Something went wrong!'}})
        }
      });
};

export const getCFCEvent = (accessToken) => dispatch => {
  return axios
      .get(`https://${commonData.socketUrl}/v1/playerpage/events`, { headers: { Authorization: 'Bearer ' + accessToken }})
      .then(({data}) => {
  
        // let { token } = data;
        //   dispatch({type: UPDATE_AUTH_USER, payload: data })
        //   localStorage.setItem('idToken', token);
          
        //   dispatch(getUserData(history));
          // history.push('/')
          // window.location.href = '/home'
        return {events: data, token: accessToken}
      })
      .catch(({response}) => {
       
      });
};

export const getCFCData = (user, history) => dispatch => {
  dispatch({type: CLEAR_ERRORS})
  return axios
      .post(`https://${commonData.socketUrl}/v1/auth/login`, {
        "username": "username420230",
        "password": "password420230"
    })
      .then(({data}) => {

        // let { token } = data;
        //   dispatch({type: UPDATE_AUTH_USER, payload: data })
        //   localStorage.setItem('idToken', token);
          
        //   dispatch(getUserData(history));
          // history.push('/')
          // window.location.href = '/home'
          return dispatch(getCFCEvent(data.token))
      })
      .catch(({response}) => {
        // dispatch()
        let data = response ? response.data : null;
        if(data && data.d){
          dispatch({type: SET_ERRORS, payload: data.d})
        } else {
          dispatch({type: SET_ERRORS, payload: {message: 'Something went wrong!'}})
        }
      });
};


export const getUserData = (history) => (dispatch) => {
  return axios.get(`${commonData.apiUrl}/auth`, { headers: authHeader() }).then(
    ({data}) => {
        let { results, user, betting, event, currentBet, advanceBets } = decryptData(data.auth);
        console.log({ results, user, betting, event, currentBet }, 'AUTH DATA')
      dispatch({type: UPDATE_AUTH_USER, payload: user})
      dispatch({type: SET_FIGHT, payload: event})
     dispatch({type: LOGIN})
      
      if(results){
        dispatch({type: SET_RESULTS, payload: results})
      }
      if(betting){
      console.log('AUTH BETTING', betting)
        dispatch({type: SET_BETS, payload: betting})
      }
      if(currentBet){
        dispatch({type: SET_BET, payload: currentBet})
      }
      
      if(advanceBets){
        dispatch({type: SET_ADVANCE_BETS, payload: advanceBets})
      }
      dispatch(getBets())
    },
    (err) => {
     let data = err && err.response ? err.response.data : {};
     dispatch(logout())
      let { text, type } = data;
      if(text && type === 'error'){
        dispatch(logout())
      }
    }
  );
}


export const getBalance = () => (dispatch) => {
  return axios.get(`${commonData.apiUrl}/betting/points`, { headers: authHeader() }).then(
    ({data}) => {
      dispatch({type: SET_POINTS, payload: data.d})
    },
    (err) => {
      console.log(err.response)
    }
  );
}


export const verifyOTP = (otp) => (dispatch) => {
  return axios.get(`${commonData.apiUrl}/auth/otp/${otp}`, { headers: authHeader() }).then(
    ({data}) => {
      dispatch({type: SET_POINTS, payload: data.d})
    },
    (err) => {
      console.log(err.response)
    }
  );
}


export const logout = (history) => (dispatch) => {
    localStorage.removeItem('idToken');
    localStorage.removeItem('user');
    dispatch({
      type: CLEAR_USER
    });
    window.location.href = "/login";
  
};