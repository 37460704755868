import * as React from 'react';
import Slider from '@mui/material/Slider';

import { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Dialog, Button, Box, DialogTitle, DialogContent, FormControl, FormLabel, FormGroup, TextField,  IconButton, Typography, InputAdornment} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';



//Icons
import CloseIcon from '@mui/icons-material/Close';


import { useDispatch, useSelector } from 'react-redux';
import { CLEAR_TRANSACTION_DATA, CLOSE_MODALS, SET_MODAL } from '../../redux/actions/types';
import { putManagedAccounts } from '../../redux/actions/Data';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="small"
          sx={{
            position: 'absolute',
            right: 4,
            top: 4,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};



const MAX = 14;
const MIN = 1;


export default function CommissionSlider() {
  const dispatch = useDispatch();
  const { user } = useSelector(({auth}) => auth);
  const { transaction} = useSelector(({usersReducer}) => usersReducer);
  const {modal, isWeb} = useSelector(({uiReducer}) => uiReducer)
  const [val, setVal] = React.useState(2.5);
  const [values, setValues] = useState({});

  const handleChange = (_, newValue) => {
    setVal(newValue);
  };
  
  const handleClose = () => {
    dispatch({type: CLOSE_MODALS})
    dispatch({type: SET_MODAL, payload: null})
  };
  
  const handleChanges = (prop) => event => {
    
    setValues({...values, [prop]: event.target.value})
  
  }
  
  const handleSubmit = (e) => {
    e.preventDefault();
    
    let res = dispatch(putManagedAccounts('agent', {userId: transaction.userId, comRate: val}))
  if(res){
    dispatch({type: CLOSE_MODALS})
    dispatch({type: SET_MODAL, payload: null})
    dispatch({type: CLEAR_TRANSACTION_DATA})

  }
  }
  
  useEffect(() => {
  if(transaction.comRate){
    setVal(transaction.comRate)
  } else {
    setVal(1)
  }
  }, [transaction])
  
  

  return (
    <BootstrapDialog
    onClose={() => handleClose()}
    aria-labelledby="customized-dialog-title"
    open={modal === 'commission'}
    maxWidth="sm"
    fullWidth
    fullScreen={!isWeb ? true : false}
  >
  <BootstrapDialogTitle id="customized-dialog-title"   onClose={() => handleClose()} className="bg-dark-blue text-white text-15">
  &nbsp;&nbsp;SET COMMISSION
  </BootstrapDialogTitle>
  <DialogContent dividers 
  className="dialog-content text-white p-3"
  >
  <form onSubmit={handleSubmit}>
  <div style={{display: 'flex', justifyContent: 'space-between'}}>
  <div>
  <Typography className='text-15 dialog-content-title'>{user.username}</Typography>      
  <Typography className='text-15 dialog-content-title'>Available Commission</Typography>      
  <Typography className='text-15 text-bold pl-2 text-yellow'>{user.comRate - val}%</Typography>  
  </div>
  <div>
  <Typography className='text-15 dialog-content-title'>{transaction.username}</Typography>      
  <Typography className='text-15 dialog-content-title'>Transferred Commission</Typography>      
  <Typography className='text-15 text-bold pl-2 text-yellow'>{val}%</Typography>  
  </div>
  </div>

      
  <br/>
 
    <Box style={{width: '100%', padding: '10px'}} >
    
    
      <Slider
        marks={[
          {
            value: 0,
            label: '',
          },
          {
            value: user.comRate - 1,
            label: '',
          },
        ]}
        step={0.5}
        value={val}
        valueLabelDisplay="auto"
        min={MIN}
        max={user.comRate - 1}
        onChange={handleChange}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          variant="body2"
          onClick={() => setVal(MIN)}
          sx={{ cursor: 'pointer' }}
        >
          {MIN} min
        </Typography>
        <Typography
          variant="body2"
          onClick={() => setVal(user.comRate - 1)}
          sx={{ cursor: 'pointer' }}
        >
          {user.comRate - 1} max
        </Typography>
      </Box>
    </Box>
      <br/>
  <Button type="submit" className='w-100 mt-2 mb-2text-bold' variant='contained'>Submit</Button>
  
  </form>
    
    </DialogContent>
</BootstrapDialog>
  );
}


