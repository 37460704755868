import * as React from 'react';
import { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Button, Checkbox, FormControl, FormGroup, FormLabel,  TextField, InputAdornment, IconButton } from '@mui/material';
import { Link, useParams } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery';

import './Login.css';
import CFCLOGO from '../../assets/cfc-logo.png'
import MessageIcon from '@mui/icons-material/Message';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useDispatch, useSelector } from 'react-redux';
import { registerUser } from '../../redux/actions/Auth';





export default function SimpleContainer(props) {
const params = useParams();
        const dispatch = useDispatch();
        const { errors } = useSelector(({uiReducer}) => uiReducer);
        const [checked, setChecked] = useState(false);
        const [values, setValues] = useState({});
        const [loading, setLoading] = useState(false);
        const [showPassword, setShowPassword] = useState({
          password: false,
          confirmPassword: false
        });
        const isMd = useMediaQuery('(min-width:600px)');
        const [rid, setRid] = useState(null);

        const handleClickShowPassword = (prop) => setShowPassword({...showPassword, [prop]: !showPassword[prop]});
 
        
    const handleChanges = (prop) => event => {
        setValues({...values, [prop]: event.target.value})
        
    }
    
    
    const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
      dispatch(registerUser({...values, isAgree: checked }, rid))
      .finally(() => {
        setLoading(false);
      })
    
    }
    
    
    useEffect(() => {
      localStorage.clear();
        
      if(params){
        setRid(params.rid)
      }
    }, [params])
    
  
  return (
    <div className='login-main'>
      <CssBaseline />
      <div className={isMd ? 'login-wrapper' : 'login-wrapper-md'}>
      <div className={isMd ? 'banner-wrapper mr-5' : 'banner-wrapper m-5'}>
      <img src={CFCLOGO} className='login-logo'/>
      </div>
        <div className='form-wrapper'>
        
        <form onSubmit={handleSubmit}>
            <FormControl component="fieldset">
       <FormLabel component="legend" className='text-light input-label'><strong>REGISTER</strong></FormLabel>
       <FormLabel component="legend" className='text-light input-label mt-2'>Mobile</FormLabel>
        <FormGroup aria-label="position" row>
            <TextField
                id="outlined-size-small"
                size="small"
                fullWidth
                className='login-input'
                value={values.phone}
                onChange={handleChanges('phone')}
              />
                  {errors.phone &&  <div className="error-div small">
                  <span
                    className="field-validation-valid text-danger"
                  > {errors.phone} </span>
                </div>}
        </FormGroup>
       <FormLabel component="legend" className='text-light input-label mt-3'>Username</FormLabel>
        <FormGroup aria-label="position" row>
            <TextField
                id="outlined-size-small"
                size="small"
                fullWidth
                className='login-input'
                value={values.username}
                onChange={handleChanges('username')}
              />
                  {errors.username &&  <div className="error-div small">
                  <span
                    className="field-validation-valid text-danger"
                  > {errors.username} </span>
                </div>}
        </FormGroup>
      <FormLabel component="legend" className='mt-3 text-light input-label'>Password</FormLabel>
      <FormGroup aria-label="position" row>
      <TextField
          id="outlined-size-small"
          value={values.password}
          onChange={handleChanges('password')}
          size="small"
          fullWidth
          className='login-input'
          type={showPassword.password ? "text" : "password"}
          InputProps={{
            endAdornment:  <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => handleClickShowPassword('password')}
              edge="end"
              className='text-light'
            >
              {showPassword.password ?  <Visibility /> : <VisibilityOff /> }
            </IconButton>
          </InputAdornment>,
          }}
         
        />
             {errors.password &&  <div className="error-div small">
                  <span
                    className="field-validation-valid text-danger"
                  > {errors.password} </span>
                </div>}
      </FormGroup>
      <FormLabel component="legend" className='mt-3 text-light input-label'>Confirm Password</FormLabel>
      <FormGroup aria-label="position" row>
      <TextField
          id="outlined-size-small"
          value={values.confirmPassword}
          onChange={handleChanges('confirmPassword')}
          size="small"
          fullWidth
          className='login-input'
          type={showPassword.confirmPassword ? "text" : "password"}
          
          InputProps={{
            endAdornment:  <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => handleClickShowPassword('confirmPassword')}
              edge="end"
              className='text-light'
            >
              {showPassword.confirmPassword ?  <Visibility /> : <VisibilityOff /> }
            </IconButton>
          </InputAdornment>,
          }}
         
        />
            {errors.confirmPassword &&  <div className="error-div small">
                  <span
                    className="field-validation-valid text-danger"
                  > {errors.confirmPassword} </span>
                </div>}
      </FormGroup>
      <FormGroup aria-label="position" row className='mt-1 d-flex justify-content-start align-items-center'>
      <Checkbox
    className={errors.isAgree ? "text-danger" : "text-white"}
  checked={checked}
  onChange={() => setChecked(!checked)}
  inputProps={{ 'aria-label': 'controlled' }}
/><a  className={errors.isAgree ? "text-danger" : "text-white"}>Terms and Conditions</a>
      </FormGroup>
      
     
      <FormGroup aria-label="position" row className='mt-3'>
        <Button type="submit" fullWidth variant='contained' disabled={loading} >REGISTER</Button>
      </FormGroup>
      <FormGroup aria-label="position" row className='mt-3'>
        <Button  fullWidth variant='outlined' className='border-light text-light'
        component={Link} to="/login"
        >LOGIN</Button>
      </FormGroup>
    </FormControl>
    </form> 
    </div>
      </div>
            
      </div>
  );
}