import * as React from 'react';
import { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Dialog, Button, Box, DialogTitle, DialogContent, FormControl, FormLabel, FormGroup, TextField,  IconButton, Typography, InputAdornment} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';



//Icons
import CloseIcon from '@mui/icons-material/Close';
import MessageIcon from '@mui/icons-material/Message';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Spinner from '../../pages/Spinner';




import { useDispatch, useSelector } from 'react-redux';
import { CLOSE_MODALS } from '../../redux/actions/types';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="small"
          sx={{
            position: 'absolute',
            right: 4,
            top: 4,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs() {
  const { isWeb, spinModal }  = useSelector(({uiReducer}) => uiReducer);
  const dispatch = useDispatch();

  

  const handleClose = () => {
    dispatch({type: CLOSE_MODALS})
  };
  

  
  


  return (
    <div>
      <BootstrapDialog
        onClose={() => handleClose()}
        aria-labelledby="customized-dialog-title"
        open={spinModal}
        maxWidth="sm"
        fullWidth
        fullScreen={true}
      >
        <BootstrapDialogTitle id="customized-dialog-title"   onClose={() => handleClose()} className="bg-dark-blue text-white text-15">
        &nbsp;&nbsp;SPIN IT TO WIN IT
        </BootstrapDialogTitle>
        <DialogContent dividers 
        className="dialog-content text-white p-5 h-300 d-flex align-items-start justify-content-center"
        >
          <div>
          <Spinner/>
          </div>
         </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
