import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { banManagedAccounts, postManagedAccounts, putManagedAccounts } from '../redux/actions/Data';
import { CLOSE_MODALS, SET_MODAL, SET_TRANSACTION_DATA } from '../redux/actions/types';

export default function MenuButton({data, onClose}) {
 const dispatch = useDispatch();
 const { user } = useSelector(({auth}) => auth);
 const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

 const handleSelect = (type) => {
    dispatch({type: SET_TRANSACTION_DATA, payload: data});
    if(type === 'commission'){
        dispatch({type: SET_MODAL, payload: 'commission'});
    }
 
 }


  const handleUser = async (type, id) => {
   let res = dispatch(postManagedAccounts(type, id))
    handleClose()
    onClose()

    // onClick()
  };
  
  
  const handleBanUser = async (id) => {
    let res = dispatch(banManagedAccounts(id))
     handleClose()
     onClose()
 
     // onClick()
   };
   
  
  const handleUserType = async (type, data) => {
  
    if(type == 'agent'){
        handleSelect('commission')
    
    } else {
        dispatch(putManagedAccounts(type, data))
        handleClose()
        onClose()
    }


 
     // onClick()
   };
  

  return (
    <div>
 
      <IconButton
      id="demo-positioned-button"
      aria-controls={open ? 'demo-positioned-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClick}
      >
      <MoreVertIcon className="text-white"/>
      
      </IconButton>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      > 

        
      {(!data.isBan)  && <div>
      {(!data.isActivated)? 
        <MenuItem onClick={() => handleUser('activate', data.userId)}>Approve</MenuItem>
        :
        <>
        {data.role == 'agent' ? <>
        <MenuItem onClick={() => handleSelect('commission')}>Set Commission</MenuItem>
        <MenuItem onClick={() => handleUserType('player', data)}>Set as Player</MenuItem>
        </>
        : 
        <>
        <MenuItem onClick={() => handleUserType('agent', data)}>Set as Agent</MenuItem>
        </>
        }
        <MenuItem onClick={() => handleUser('deactivate', data.userId)}>Deactivate</MenuItem>
        
        </>

        }
        </div>
         }
         {(user.role == 'agent') &&
          <MenuItem onClick={() => handleBanUser(data.userId)}>{data.isBan ? 'Reactivate User' :'Ban User'} </MenuItem>
        }  
      </Menu>
    </div>
  );
}
