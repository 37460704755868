import { combineReducers } from 'redux';

import Auth from './Auth';
import Users from './Users';
import uiReducer from './Ui';
import Data from './Data';
import Betting from './Betting';
import streamReducer from './streamReducer';



export default history =>
  combineReducers({
    auth: Auth,
    betting: Betting,
    usersReducer: Users,
    uiReducer: uiReducer,
    dataReducer: Data,
    stream: streamReducer
  });
