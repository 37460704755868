import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { List, ListItem, ListItemAvatar, ListItemText, Avatar } from '@mui/material';


//Icons
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { GCashAccounts } from '../utils/dummyData';




export default function RecipeReviewCard() {
  const [expanded, setExpanded] = React.useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card >
      <CardHeader
        action={
          <IconButton aria-label="settings"
          onClick={() => handleExpandClick()}
          >
            {expanded ? 
                <ExpandLessIcon className='text-white'/> 
                :
                <ExpandMoreIcon className='text-white'/>
            }
            
          </IconButton>
        }
        className="gcash-header"
        title={<Typography className='text-white text-bold'>INSTRUCTIONS</Typography>}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent className='gcash-card-wrapper '>
          <div className='gcash-description'> Send a minimum of 
          <span > 100.00 </span>
          to any of the numbers below 
          </div>
          <List dense={true} >
          {
            GCashAccounts.map((a, index) => {
          return (
            <ListItem  
            key={index}
            className="gcash-list-item mt-1 mb-2 bg-paper"
            >
              <ListItemText className='text-dark' primary={<strong>{a.number}</strong>} secondary={<strong>{a.name}</strong>} />
               <ListItemAvatar >
                  <AccountCircleIcon  className='text-dark gcash-people-icon'/>
              </ListItemAvatar>
            </ListItem>
            )
          })
          }
    </List>
             <div className='gcash-description mb-3'> 
             Our GCash number change every 10 minutes. Refresh this page before sending. 
             </div>
              <div className='gcash-description mb-3'> 
              Fill the reference no, amount, receiver number in the form below. 
              </div>
              <div className='gcash-description mb-3'> 
              Attach the screenshot receipt in the attachment field. 
              </div>
        </CardContent>
      </Collapse>
    </Card>
  );
}
