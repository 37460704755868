
export const betAmounts = [
  10, 20, 50, 100, 500, 1000, 5000, 10000
]


export const GCashAccounts = [
  {
      name: "R**L T.",
      number: "09165223072",
      value: "R**L T."
  }
  ]

  export const SpinSlots = [
    {
        "start": 346,
        "end": 359,
        "slot": 1
    },
    {
        "start": 332,
        "end": 345,
        "slot": 2
    },
    {
        "start": 318,
        "end": 331,
        "slot": 3
    },
    {
        "start": 304,
        "end": 317,
        "slot": 4
    },
    {
        "start": 289,
        "end": 303,
        "slot": 5
    },
    {
        "start": 276,
        "end": 288,
        "slot": 6
    },
    {
        "start": 262,
        "end": 275,
        "slot": 7
    },
    {
        "start": 247,
        "end": 261,
        "slot": 8
    },
    {
        "start": 233,
        "end": 246,
        "slot": 9
    },
    {
        "start": 218,
        "end": 232,
        "slot": 10
    },
    {
        "start": 204,
        "end": 217,
        "slot": 11
    },
    {
        "start": 188,
        "end": 203,
        "slot": 12
    },
    {
        "start": 173,
        "end": 187,
        "slot": 13
    },
    {
        "start": 159,
        "end": 172,
        "slot": 14
    },
    {
        "start": 143,
        "end": 158,
        "slot": 15
    },
    {
        "start": 129,
        "end": 142,
        "slot": 16
    },
    {
        "start": 114,
        "end": 128,
        "slot": 17
    },
    {
        "start": 100,
        "end": 113,
        "slot": 18
    },
    {
        "start": 85,
        "end": 99,
        "slot": 19
    },
    {
        "start": 71,
        "end": 84,
        "slot": 20
    },
    {
        "start": 56,
        "end": 70,
        "slot": 21
    },
    {
        "start": 42,
        "end": 55,
        "slot": 22
    },
    {
        "start": 28,
        "end": 41,
        "slot": 23
    },
    {
        "start": 14,
        "end": 27,
        "slot": 24
    },
    {
        "start": 1,
        "end": 13,
        "slot": 25
    }
]
