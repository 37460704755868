import * as React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Dialog, Box, DialogTitle, DialogContent, IconButton, Typography, FormControl, TextField, Button, FormGroup, MenuItem, FormLabel } from '@mui/material';


//Icons
import CloseIcon from '@mui/icons-material/Close';
import GCASHLOGO from '../../assets/gcash.jpg'
import { GCashAccounts } from '../../utils/dummyData';


//Components
import { useDispatch, useSelector } from 'react-redux';
import { CLOSE_MODALS, SET_ERRORS, CLEAR_ERRORS } from '../../redux/actions/types';
import { addCredits } from '../../redux/actions/Users';
import ReceiverInput from '../ReceiverInput';
import { getUserData } from '../../redux/actions/Auth';




const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="small"
          sx={{
            position: 'absolute',
            right: 4,
            top: 4,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs() {
const dispatch = useDispatch();
  const { isWeb, pointsModal, errors, withdrawalModal, modal }  = useSelector(({uiReducer}) => uiReducer);
  const { user } = useSelector(({auth}) => auth);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [values, setValues] = useState({
    amount: null,
    commission: null,
    type: "deposit",
    note: '',
    fund: 'points',
    // phone: user.phone
  });

  const handleChangeReceiver = (e) => {
  console.log(e, 'receiver')
      setValues({...values, phone: e ? e.phone : null})
      if(e && e.phone !== 'Commission To Points'){
        setSelected(e)
      } else {
        setSelected(null)
      }
  } 

  
  
const handleChanges = (prop) => event => {


  setValues({...values, [prop]: event.target.value})
  
}

  const handleClose = () => {
    dispatch({type: CLOSE_MODALS})
  };


const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    dispatch({type: CLEAR_ERRORS})
    dispatch(addCredits(values))
    .then(() => {   
      setValues({
        amount: 0,
        type: "deposit",
        note: '',
        // phone: user.phone,
        commission: 0
      })
      dispatch({type: CLOSE_MODALS});
      dispatch(getUserData());
      setLoading(false)
    })
    .catch(err => {
    console.log(err, 'ERR')
      dispatch({type: SET_ERRORS, payload: err.response.data})    
      setLoading(false)

    })    
}



useEffect(() => {
    if(pointsModal){
      setValues({...values, phone: '', type: 'deposit'})
    } else if(withdrawalModal){
      setValues({...values, phone: '', type: 'withdrawal'})
    } else if(modal == 'cashoutRequest') {
      setValues({...values, phone: user.phone, type: 'cashoutRequest'})
    }
    
    return () => {
      setSelected(null)
    }

}, [pointsModal, withdrawalModal, modal]);





console.log(withdrawalModal, modal, 'MODAL  ')
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={pointsModal || withdrawalModal || modal == 'cashoutRequest'}
        maxWidth="sm"
        fullWidth={true}
        fullScreen={!isWeb}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} className="bg-dark-blue text-white text-15">
        &nbsp;&nbsp; {pointsModal ? 'ADD CREDITS' : withdrawalModal ? 'WITHDRAW CREDITS' : 'CASHOUT' }
        </BootstrapDialogTitle>
        <DialogContent 
        dividers 
        className="dialog-content text-white p-3"
        >
        <form onSubmit={handleSubmit} >
        <Box className="p-2" style={{display: 'flex', justifyContent: 'space-between'}}>
        <Box style={{flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
        <Typography className='text-15 dialog-content-title mt-3'>TRANSACTION TYPE</Typography>      
        <Typography className='text-15 text-bold mt-2 pl-2 text-yellow'>{pointsModal ? 'DEPOSIT' : withdrawalModal ? 'WITHDRAW' : 'CASHOUT' }</Typography>
        </Box>
        <Box style={{flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
        <Typography className='text-14 dialog-content-title mt-3'>TOTAL POINTS</Typography>      
        <Typography className='text-13 text-bold mt-2 pl-2 text-yellow'>₱{Number(selected ? selected.points : user.points).toFixed(2)}</Typography>
        </Box>
        <Box style={{flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
        {user.role == 'agent' && <>
        
        <Typography className='text-14 dialog-content-title mt-3'>TOTAL COMMISSION</Typography>      
        <Typography className='text-13 text-bold mt-2 pl-2 text-yellow'>₱{Number(selected ? selected.load : user.load).toFixed(2)}</Typography>
        </>
      }
        
        </Box>
        </Box>
        <br/>
      {user.role == 'user' && 
        <div className='gcash-wrapper'>
        <img src={GCASHLOGO} width="64px" className='gcash-img'/>
      </div>
      }
      <div className='gcash-form bg-gcash-form p-3' >
     <FormControl component="fieldset">
     {user.role == 'agent' && <>
     <FormLabel component="legend" className='text-light input-label mt-2'>{pointsModal ? 'Send Points To:' : withdrawalModal ? 'Withdraw From:' : '' }</FormLabel>
     <FormGroup aria-label="position" row>
         <ReceiverInput
            value={values.phone}
            handleChanges={handleChangeReceiver}
            isWithdraw={withdrawalModal}
         />
     </FormGroup>
 
     </>}
     <FormLabel component="legend" className='text-light input-label mt-2'>Points</FormLabel>
     <FormGroup aria-label="position" row>
         <TextField
             id="outlined-size-small"
             size="small"
             fullWidth
             placeholder="0"
             className='login-input'
             value={values.amount}
             type="number"
             onChange={handleChanges('amount')}
           />
     </FormGroup>  
     {withdrawalModal && (selected && selected.role == 'agent') && <>
     
      <FormLabel component="legend" className='text-light input-label mt-3'>Commission</FormLabel>
        <FormGroup aria-label="position" row>
            <TextField
                id="outlined-size-small"
                size="small"
                fullWidth
                placeholder="0"
                className='login-input'
                value={values.commission}
                type="number"
                onChange={handleChanges('commission')}
              />
        </FormGroup>
        </>
        }
      
     {modal == 'cashoutRequest' && 
      <>
      <FormLabel component="legend" className='text-light input-label mt-3'>Receiver Name</FormLabel>
      <FormGroup aria-label="position" row>
          <TextField
              id="outlined-size-small"
              size="small"
              fullWidth
                className='login-input'
              value={values.receiver}
              onChange={handleChanges('receiver')}
            >
            
            </TextField>
      </FormGroup>
      <FormLabel component="legend" className='text-light input-label mt-3'>Receiver Number <span className='text-yellow text-12'>***Your Gcash Number</span></FormLabel>
      <FormGroup aria-label="position" row>
          <TextField
              id="outlined-size-small"
              size="small"
              placeholder="Ex: 09xxxxxxxxx"
              fullWidth
              className='login-input'
              value={values.note}
              onChange={handleChanges('note')}
            />
      </FormGroup>
    
      </>
     
     }
  
  

   
      

     
      </FormControl>
      <div className="mt-5 "/ >
      {errors.message && <p className="text-center text-danger">{errors.message}</p>}
      
            <Button className='w-100 text-bold' variant='contained'  
            disabled={loading}
            type="submit"
            >SUBMIT P2P</Button>
          </div>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
