import * as React from 'react';
import { Alert, Snackbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { CLEAR_SNACKBAR } from '../redux/actions/types';

export default function PositionedSnackbar() {
    const dispatch = useDispatch();
    const {snackbar } = useSelector(({uiReducer}) => uiReducer);
    const [open, setOpen] = React.useState(false);
    const [values, setValues] = React.useState({ 
            message: '',
            type: 'success',
            color: 'info'
    })


  const handleClose = () => {
        setOpen(false);
        dispatch({type: CLEAR_SNACKBAR})
  };

  React.useEffect(() => {
    if(snackbar && snackbar.message && !open){
      setValues(snackbar)
      setOpen(true)
    }

  }, [snackbar])


  return (
    <div>
      <Snackbar
        anchorOrigin={{   
            vertical: 'top',
            horizontal: 'center'
        }}
        autoHideDuration={3000}
        open={open}
        onClose={handleClose}
        key={'top' + 'center'}
        className="m-w-100"
      >
        <Alert variant="filled" severity={values.type}>{values.message}</Alert>
        </Snackbar>
    </div>
  );
}
