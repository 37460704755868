import * as React from 'react';
import { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Dialog, Button, Box, DialogTitle, DialogContent, FormControl, FormLabel, FormGroup, TextField,  IconButton, Typography, InputAdornment} from '@mui/material';



//Icons
import CloseIcon from '@mui/icons-material/Close';




import { useDispatch, useSelector } from 'react-redux';
import { CLEAR_TRANSACTION_DATA, CLOSE_MODALS, SET_MODAL } from '../../redux/actions/types';
import { clearBetsHistory, handlePoints } from '../../redux/actions/Users';
import { getUserData } from '../../redux/actions/Auth';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="small"
          sx={{
            position: 'absolute',
            right: 4,
            top: 4,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AddLoad() {
  const dispatch = useDispatch();
  const { transaction} = useSelector(({usersReducer}) => usersReducer);
  const { isWeb, modal }  = useSelector(({uiReducer}) => uiReducer);
    const [values, setValues] = useState({});

  
  const handleClose = () => {
    dispatch({type: SET_MODAL, payload: null});
    dispatch({type: CLEAR_TRANSACTION_DATA})
    
  };
  
  const handleChanges = (prop) => event => {
    setValues({...values, [prop]: event.target.value})
  }
  
  const handleSubmit = (e) => {
    e.preventDefault();
    
    dispatch(handlePoints(values))
    .then(() => {
      dispatch(getUserData());
    })
    .catch(err => {
      console.log(err)
    })
  
    
  
  }
  
  
  useEffect(() => {
    setValues(transaction)
  }, [transaction])


  return (
    <div>
      <BootstrapDialog
        onClose={() => handleClose()}
        aria-labelledby="customized-dialog-title"
        open={(modal == 'addLoad' || modal == 'withdrawLoad')}
        maxWidth="sm"
        fullWidth
        fullScreen={!isWeb ? true : false}
      >
        <BootstrapDialogTitle id="customized-dialog-title"   onClose={() => handleClose()} className="bg-dark-blue text-white text-15">
        &nbsp;&nbsp; {modal === 'withdrawLoad' ? 'Withdraw Points' : 'Add Credits '} 
        </BootstrapDialogTitle>
        <DialogContent dividers 
        className="dialog-content text-white"
        >
        <form onSubmit={handleSubmit}>
        <Typography className='text-15 dialog-content-title'>TRANSACTION TYPE</Typography>      
        <Typography className='text-15 text-bold mt-2 pl-2 text-yellow'> {modal === 'withdrawLoad' ? 'Points Withdrawal' : 'Points Transfer'}</Typography>      
        <FormGroup aria-label="position" row style={{display: 'flex', justifyContent: 'space-between'}}>
        <div style={{width: '47%'}} >

        <FormLabel component="legend" className='text-light input-label mt-3'>Mobile Number</FormLabel>
            <TextField
                
                id="outlined-size-small"
                size="small"
                style={{width: '100%'}}
                className='login-input'
                value={values.phone}
                onChange={handleChanges('phone')}
              /> 
           </div>
              <div style={{width: '47%'}} >
              <FormLabel component="legend" className='text-light input-label mt-3'>Amount</FormLabel>
              <TextField
              id="outlined-size-small"
              size="small"
              style={{width: '100%'}}
              className='login-input'
              type="number"
              value={values.amount}
              onChange={handleChanges('amount')}
            />
            </div>
        </FormGroup>
        <FormLabel component="legend" className='text-light input-label mt-3'>Note/Details</FormLabel>
        <FormGroup aria-label="position" row>
            <TextField
                id="outlined-size-small"
                size="small"
                fullWidth
                className='login-input'
                value={values.note}
                onChange={handleChanges('note')}
              />
        </FormGroup>
        <br/>
       
        <Button type="submit" className='w-100 mt-2 mb-2text-bold' variant='contained'>Submit</Button>
        <br/>
        </form>
 </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
