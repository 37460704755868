import * as React from 'react';
import { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Dialog, Box, Divider, Button, DialogTitle, DialogContent, FormLabel, FormGroup, TextField,  IconButton, Typography, InputAdornment} from '@mui/material';
import '../../App.css';



//Icons
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { CLOSE_MODALS, SET_ERRORS, CLEAR_ERRORS, SET_SNACKBAR } from '../../redux/actions/types';
import { clearBetsHistory, updatePassword, updateUsername } from '../../redux/actions/Users';
import { getUserData } from '../../redux/actions/Auth';
import { betMatch, getBet } from '../../redux/actions/Data';
import RemoveIcon from '@mui/icons-material/Remove';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="small"
          sx={{
            position: 'absolute',
            right: 4,
            top: 4,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AdvanceBets() {
  const dispatch = useDispatch();
  const { advanceBets, currentFight} = useSelector(({betting}) => betting);
  const { isWeb, modal, errors }  = useSelector(({uiReducer}) => uiReducer);
  const { user } = useSelector(({auth}) => auth);
  const [error, setError] = useState(null);

    const [values, setValues] = useState({
      amount: 0,
      betSide: '',
      fightNo: currentFight.fightNo + 1
    });
    const [loading, setLoading] = useState(false);

  const handleClose = () => {
    dispatch({type: CLOSE_MODALS})
  };
  
  const handleChanges = (prop) => event => {
    
    setValues({...values, [prop]: event.target.value})
  
  }
  

  const handleClickFightNo = (val) => {
  let int = values.fightNo 
    if(val == 'add'){
      int = int == 400 ? 400 : values.fightNo + 1;
      setValues({...values, fightNo: int})

    } else {
      int = int > currentFight.fightNo + 1 ? int - 1  : int ;
      setValues({...values, fightNo: int})
    }
  }
  
  
  const handleAdd = async (e) => {
    dispatch({type: CLEAR_ERRORS})
    setError(null)
    if(!Number(values.amount)){
      return setError('Invalid Amount')
    }
  
    
    if(values.amount >= (user.points + 1) ){
        return setError('Insuffecient Fund!');
      } 
      
      if(!values.betSide){
        return setError('Select bet side')
      }
      setLoading(true)

    
    
   
    
    if(user.points !== 0){
      dispatch(betMatch({...values, side: values.betSide, amount: values.amount, isScheduled: true}))
      .then(() => {
        // setTimeout(() => {
          dispatch({type: SET_SNACKBAR, payload: { message: 'Bet submitted successfully!', type: 'success', color: 'success' }})
          // dispatch({type: CLOSE_MODALS})
          setLoading(false)
          dispatch(getUserData())
          setValues({
            amount: 0,
            betSide: '',
            fightNo: values.fightNo
            })
        // }, 3000)
      })
      .catch(({response}) => {
        let { data } = response;
        // setTimeout(() => {
          dispatch({type: SET_SNACKBAR, payload: { message: data.m, type: 'error', color: 'danger' }})
          dispatch({type: SET_ERRORS, payload: data.errors})

          // dispatch({type: CLOSE_MODALS})
          setLoading(false)
        // }, 5000)
      })
    } else {
      alert('Not enough points')
    }
  }
  
  useEffect(() => {
  if(modal == 'advanceBets'){
    dispatch(getUserData());
    setValues({
      amount: 0,
      betSide: '',
      fightNo: currentFight.fightNo + 1
      })
      setLoading(false)

  }

  }, [modal])


  return (
    <div>
      <BootstrapDialog
        onClose={() => handleClose()}
        aria-labelledby="customized-dialog-title"
        open={modal == 'advanceBets'}
        maxWidth="sm"
        fullWidth
        fullScreen={!isWeb ? true : false}
      >
        <BootstrapDialogTitle id="customized-dialog-title"   onClose={() => handleClose()} className="bg-dark-blue text-white text-15">
        &nbsp;&nbsp;ADVANCE BETS
        </BootstrapDialogTitle>
        <DialogContent dividers 
        className="dialog-content text-white"
        >
        <Box style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingRight: '20px',
        }}>
        <Typography>Total Points</Typography>
        <Typography className="text-yellow text-18 text-bold">₱{Number(user.points).toFixed(2)}</Typography>
        </Box>
    {error && <div className="w-100 text-center"><Typography color="error">{error}</Typography></div>}
    <br/>
    <Box className="pb-2" style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>

        
          <div className={`clickable meron w-100 ${values.betSide == 'meron' ? 'opacity-100' : 'opacity-25'}`} style={{height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
            onClick={() => setValues({...values, betSide: 'meron'})}
            >
          Meron
          </div>
          <div className={`clickable wala w-100 ${values.betSide == 'wala' ? 'opacity-100' : 'opacity-25'}`} style={{height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
            onClick={() => setValues({...values, betSide: 'wala'})}
          >
          Wala
          </div>
          <div className={`clickable draw w-100 ${values.betSide == 'draw' ? 'opacity-100' : 'opacity-25'}`} style={{height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
              onClick={() => setValues({...values, betSide: 'draw'})}
          >
          Draw
          </div>
        </Box>
        <Box className="d-flex">
        
        <div className="flex-grow-1 mr-1">
        <FormLabel component="legend" className='text-light input-label mt-3'>Fight #</FormLabel>
        <div style={{display: 'flex'}}>
        <div className={` opacity-8 cancelled`} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <IconButton size="small" style={{height: '15px'}}
            onClick={() => handleClickFightNo('less')}
            ><RemoveIcon/></IconButton>
        </div>
        <div  style={{backgroundColor: 'rgb(34, 39, 58)', height: '40px', width: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <p className='mb-0 text-white'>{values.fightNo}</p> 
        </div>
        <div className={`opacity-8 cancelled centered`} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <IconButton size="small" style={{height: '15px'}}
        onClick={() => handleClickFightNo('add')}
        ><AddIcon/></IconButton>
    </div>
        </div>
    
         </div>
        <div className="flex-grow-1 ml-1">
       <FormLabel component="legend" className='text-light input-label mt-3'>Amount</FormLabel>
        <FormGroup aria-label="position" row>
            <TextField
                id="outlined-size-small"
                size="small"
                fullWidth
                type="number"
                className='login-input'
                value={values.amount}
                onChange={handleChanges('amount')}
              />
        </FormGroup>
        </div>
        </Box>
        <br/>
      <Button className='w-100 mt-2 mb-2text-bold' variant='contained' disabled={loading} onClick={() => handleAdd()}>Add</Button>
      <Box>
      <br/>
      <div className="transaction-list pt-5" style={{maxHeight: '50vh', overflowX: 'hidden'}}>
      <Divider className='mt-1 mb-3 divider-bot' />
      {advanceBets.length == 0 && <div className="w-100 text-center"><Typography className="text-center">No Advance Bets</Typography></div>}
      {advanceBets.map((a, index) => {
        return (
          <div key={index} className="transaction-list-item mb-3">
          <div className="transaction-list-item-text">
          <div className='w-100 d-flex align-items-center justify-content-start bets-card pr-1'>
          <div className={`bet-number opacity-8 ${a.betSide}`}>
          <p className='body-2 mb-0'>  #{a.fightNo}</p> 
          </div>
          <div >
          <p className='body-2 mb-0 text-white'> {Number(a.amount).toFixed(2)}</p> 
          </div>
          <div className="flex-spacer"/>
          {a.status === "done" && 
          <div className='d-flex align-items-center justify-content-center'>
                  {a.isWin ?
              <>
            <div className='text-green'>WIN {Number(a.payout).toFixed(2)}</div>&nbsp;&nbsp;
            <small className='ml-2 text-white'>{Number(a.payoutRate * 100).toFixed(0)}%</small>
            </>
            :
            <div className='text-lose'>{a.winSide === "draw" ? "RETURNED - DRAW" : a.winSide === "cancelled" ? "CANCELLED" : "LOSE"}</div>
          }
          
          </div>
          
          
           }
          </div>
          </div>
          <div className="transaction-list-action text-center">
          
          </div>
          </div>)
        })}
        </div>
      </Box>
       </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
