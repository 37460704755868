import React, { useEffect, useState } from 'react'
import { 
     Grid,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { getBets } from '../redux/actions/Data';
import { SET_BETS_HISTORY } from '../redux/actions/types';




export default function Trends() {
  const { betsHistory} = useSelector(({usersReducer}) => usersReducer);






let bets = betsHistory.map((a, index) => {
    return (
      <Grid item lg={6} md={6} sm={12} xs={12} key={index}>
          <div className='w-100 d-flex align-items-center justify-content-start bets-card pr-1'>
          <div className={`bet-number opacity-8 ${a.betSide}`}>
          <p className='body-2 mb-0'>  #{a.fightNo}</p> 
          </div>
          <div >
          <p className='body-2 mb-0 text-white'> {Number(a.amount).toFixed(2)}</p> 
          </div>
          <div className="flex-spacer"/>
          
          {a.isScheduled && <AccessTimeIcon className="advance-bet"/>}
          &nbsp;
          {a.status === "done" && 
          <div className='d-flex align-items-center justify-content-center'>
                  {a.isWin ?
              <>
            <div className='text-green'>WIN {Number(a.payout).toFixed(2)}</div>&nbsp;&nbsp;
            <small className='ml-2 text-white'>{Number(a.payoutRate * 100).toFixed(0)}%</small>
            </>
            :
            <div className='text-lose'>{a.winSide === "draw" ? "RETURNED - DRAW" : a.winSide === "cancelled" ? "CANCELLED" : "LOSE"}</div>
          }
          
          </div>
          
          
           }
          </div>
      </Grid>
    )
})



  return (
  <>
  {betsHistory.length === 0 ? 
     <div className='w-100 text-center'>      
      <small className='text-lose text-center'>NO RECENT BETS</small>
    </div>
      :
    <Grid container spacing={2}>
      {bets}
    </Grid>      
  }
    </>
  )
}
