import { SET_USER, SET_BETS_HISTORY, CLEAR_BETS_HISTORY, SET_TRANSACTION_DATA, CLEAR_TRANSACTION_DATA } from '../actions/types';

const INIT_STATE = {
  user: {},
  betsHistory: [],
  transaction: {
      userId: null,
      amount: 0,
      note: '',
      phone: null
  }
};

export default (state = INIT_STATE, action) => {
  let { type, payload } = action;

  switch (type) {
    case SET_USER: {
      return {
        ...state,
        user: payload,
      };
    }
    
    case SET_TRANSACTION_DATA: {
      return {
        ...state,
        transaction: payload,
      };
    }
    
    case CLEAR_TRANSACTION_DATA: {
      return {
        ...state,
        transaction: {
          userId: null,
          amount: 0,
          note: '',
          phone: null
      },
      };
    }
    
    case SET_BETS_HISTORY: {
      return {
        ...state,
        betsHistory: payload,
      };
    }
    
    case CLEAR_BETS_HISTORY: {
      return {
        ...state,
        betsHistory: [],
      };
    }
    
    case SET_USER: {
      return {
        ...state,
        user: payload,
      };
    }
   
    default:
      return state;
  }
};
