import * as React from 'react';
import { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Dialog, Chip, Divider, Box, DialogTitle, DialogContent, MenuItem, TextField,  IconButton, Typography, InputAdornment} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';




//Icons
import CloseIcon from '@mui/icons-material/Close';






import { useDispatch, useSelector } from 'react-redux';
import { CLOSE_MODALS } from '../../redux/actions/types';
import { getCashRequests } from '../../redux/actions/Users';
import moment from 'moment/moment';


const FILTER_BY = [
  {label: 'Deposits', value: 'deposit'},
  {label: 'Withdrawals', value: 'withdrawal'},
]

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="small"
          sx={{
            position: 'absolute',
            right: 4,
            top: 4,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs() {
  const { isWeb, cashRequestsModal }  = useSelector(({uiReducer}) => uiReducer);
  const dispatch = useDispatch();
    const [today, setToday] = useState(moment(moment().format('YYYY-MM-DD')));
    const [filterBy, setFilterBy] = useState('deposit');
    const { cashRequests }  = useSelector(({auth}) => auth);
    const [transList, setTransList] = useState([]);
  

  const handleClose = () => {
    dispatch({type: CLOSE_MODALS})
  };
  

  const handleTransactions = async (fltr, dt) => {
   let transData = await dispatch(getCashRequests(fltr, dt));
    if(transData && transData.length !== 0){
      setTransList(transData)
    } else {
      setTransList([])
    }
    
    
  }

  const handleChanges = (e, type) => {
    let dt = today;
    let fltr = filterBy;
    
    if(type == 'date'){
      dt = e;
      setToday(e)
    } else {
      fltr = e;
      setFilterBy(e)
    }
    
    
    handleTransactions(fltr, dt)
}

  
  
  
  useEffect(() => {
  if(cashRequestsModal){
    handleTransactions(filterBy, today);
  }
}, [cashRequestsModal])



  return (
    <div>
      <BootstrapDialog
        onClose={() => handleClose()}
        aria-labelledby="customized-dialog-title"
        open={cashRequestsModal}
        maxWidth="sm"
        fullWidth
        fullScreen={!isWeb ? true : false}
      >
        <BootstrapDialogTitle id="customized-dialog-title"   onClose={() => handleClose()} className="bg-dark-blue text-white text-15">
        &nbsp;&nbsp;CASH REQUESTS
        </BootstrapDialogTitle>
        <Box className="p-2" style={{display: 'flex', justifyContent: 'space-between'}}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker 
          value={today}
          defaultValue={moment(new Date()).format('YYYY-MM-DD')}
          onChange={(newValue) => handleChanges(newValue, 'date')}
          style={{width: '45%', color: 'white'}}
        />
      </LocalizationProvider>
      <TextField
      id="outlined-size-small"
      select
      style={{width: '45%', color: 'white'}}
        color="primary"
      // className='login-input'
      defaultValue={filterBy}
      value={filterBy}
      onChange={(e) => handleChanges(e.target.value, 'filter')}
    >
    {FILTER_BY.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    )) }
    </TextField>
      </Box>
        <DialogContent dividers 
        className="dialog-content text-white m-h-300"
        >
    
        <br/>
        <div className="transaction-list">
          {transList.map(a => {
            return (
              <div className="transaction-list-item mb-3">
              <div className="transaction-list-item-text">
              <strong className='text-yellow text-1-rem'>₱{Number(a.amount).toFixed(2)}</strong>&nbsp;
              <br/>
              <small> From: &nbsp;&nbsp;&nbsp;<Chip size='small' label={a.paymentMethod} color="primary" variant="contained" className="chip" /></small><br/>
              </div>
              <div className="transaction-list-action text-center">
              <small>  {moment(a.createdAt).format('lll')} </small><br/>
              <Chip size='small' label={String(a.status).toUpperCase()} color={a.status === 'valid' ? 'success' : a.status === 'invalid' ? 'error' : 'warning'} variant="contained" className="chip-valid" />
              </div>
              <hr className="text-dark"/>
            </div>
            )

          })}
       
        
         </div>
         </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
