import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

import MessageIcon from '@mui/icons-material/Message';
import PersonIcon from '@mui/icons-material/Person';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { useDispatch, useSelector } from 'react-redux';
import { CLOSE_MODALS, SET_ACTIVE_TAB, SET_MODAL_RULES } from '../redux/actions/types';


export default function SimpleBottomNavigation({setTab, tab}) {
  const dispatch = useDispatch();
  const { activeTab } = useSelector(({uiReducer}) => uiReducer);

  const handleTab = (e) => {
     dispatch({type: SET_ACTIVE_TAB, payload: e});
      return dispatch({type: CLOSE_MODALS})
    
  }

  return (
    <Box className="btn-nav-wrapper" >
      <BottomNavigation
        showLabels
        value={activeTab}
        onChange={(event, newValue) => {
          handleTab(newValue);
        }}
        className="btn-nav-wrapper" 
      >
        <BottomNavigationAction label="Rules" icon={<MessageIcon />} />
        <BottomNavigationAction label="Play" icon={<PlayCircleOutlineIcon />} />
        <BottomNavigationAction label="Account" icon={<PersonIcon />} />
      </BottomNavigation>
    </Box>
  );
}
