import * as React from 'react';
import { useState, useEffect } from 'react';
import { List, Divider, ListItemText, ListItemIcon, ListItemButton, CardHeader, Avatar, Typography, IconButton}  from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import '../../App.css';
import { Box } from '@mui/system';
import ProfileImage from '../../assets/profile.jpg';
import PaymentsIcon from '@mui/icons-material/Payments';

//Icons
import RedeemIcon from '@mui/icons-material/Redeem';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import SubjectIcon from '@mui/icons-material/Subject';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import LogoutIcon from '@mui/icons-material/Logout';
import MessageIcon from '@mui/icons-material/Message';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Groups2Icon from '@mui/icons-material/Groups2';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/actions/Auth';
import { SET_MODAL, SET_MODAL_BET, SET_MODAL_CASH_REQUESTS, SET_MODAL_POINTS, SET_MODAL_PROMO, SET_MODAL_RULES, SET_MODAL_SETTINGS, SET_MODAL_SPIN, SET_MODAL_SUPPORT, SET_MODAL_TRANSACTIONS, SET_MODAL_USERS, SET_MODAL_WITHDRAWAL } from '../../redux/actions/types';

//Components
import CopyToClipboard from '../../components/CopyToClipBoard';
import { getManagedAccounts } from '../../redux/actions/Data';
import { getCashPendingRequests } from '../../redux/actions/Users';



function Sidebar(props) {
const dispatch = useDispatch();
const { user } = useSelector(({auth}) => auth );
const [approvals, setApprovals] = useState([]);
const [cashRequests, setCashRequests] = useState([]);

const handleTransactions = async (fltr, dt) => {
  let requestsData = await dispatch(getCashPendingRequests());
  setCashRequests(requestsData);
 }
  
const handleGetAccounts = (type) => {
  dispatch(getManagedAccounts(type))
  .then(res => {  
    console.log(res.d, 'RESS')
      setApprovals(res.d);
  })
  .catch(err => {
      console.log('SOMETHING WENT WRONG!')
  })
}

useEffect(() => {
    handleGetAccounts('approvals')
    handleTransactions()
}, [])


  return (
  <>
    <div className='account-info'>
    {/* //User Profile */}
      <Box  className='user-profile'>
      <CardHeader
        avatar={
          <Avatar size='lg' src={ProfileImage} 
          sx={{ width: 50, height: 50 }}
          />
        }
        action={
          <div>
          {/* awd */}
          {user.role !== 'user' && 
          <CopyToClipboard refCode={user.refCode}/>
        }
          &nbsp;
          <IconButton aria-label="settings" onClick={() => dispatch({type: SET_MODAL_RULES})}>
             <HelpIcon className='help-btn'/>
          </IconButton>
          </div>
        }
        title={<Typography color="white">{user.username}</Typography>}
        subheader={<Typography color="white">0{user.phone}</Typography>}
      />
      
      <Divider className='text-white bg-white w-100 pofile-divider'/>
      <Box style={{display: 'flex', justifyContent: 'space-between'}}>
      <Box sx={{p:2}}>
      <Typography variant="body2" color="grey">
      Account Points
      </Typography>
      <strong className='points-text'>
       {Number(user.points).toFixed(2)}
      </strong>
      </Box>
      {user.role !== 'user' && 
      <Box sx={{p:2}}>
        <Typography variant="body2" color="grey">
       Total Commission
        </Typography>
        <strong className='points-text'>
         {Number(user.load).toFixed(2)} <small style={{marginBottom: '5px', fontSize: '14px', color: 'white'}}> ({user.comRate}%)</small>
        </strong>
        </Box>
      }
        </Box>
    </Box>
    
    
    {/* //Account Actions */}
    <Box className='user-profile'>
    {user.role === 'user' ? 
      <div className='user-actions'>
      <button className='btn-add-points mr-1' onClick={() => dispatch({type: SET_MODAL, payload: 'cashinRequest'})}>ADD POINTS</button>
      <button className='btn-withdraw ml-1' onClick={() => dispatch({type: SET_MODAL, payload: 'cashoutRequest'})}>WITHDRAW</button>
      </div>
      :
      <div className='user-actions'>
    <button className='btn-add-points mr-1' onClick={() => dispatch({type: SET_MODAL_POINTS})}>ADD POINTS</button>
    <button className='btn-withdraw ml-1' onClick={() => dispatch({type: SET_MODAL_WITHDRAWAL})}>WITHDRAW</button>
    </div>
    }
    
  </Box>
    <Box className='user-profile'>
    <List component="nav" aria-label="main mailbox folders" 
     className="text-white"
    subheader={<div className='p-2 text-gray'>
          Account Settings
          </div>
      }>
        <ListItemButton
          onClick={() => dispatch({type: SET_MODAL_SETTINGS})}
        >
          <ListItemIcon>
            <DriveFileRenameOutlineIcon className='text-white' />
          </ListItemIcon>
          <ListItemText primary="Edit Account" />
        </ListItemButton>
        <ListItemButton
                  onClick={() => dispatch({type: SET_MODAL_PROMO})}
        >
          <ListItemIcon>
            <RedeemIcon  className='text-white' />
          </ListItemIcon>
          <ListItemText primary="Promo" />
        </ListItemButton>
      
        <ListItemButton
        onClick={() => dispatch({type: SET_MODAL_TRANSACTIONS})}
        >
          <ListItemIcon>
            <SubjectIcon  className='text-white' />
          </ListItemIcon>
          <ListItemText primary="Transactions" />
        </ListItemButton>
        <ListItemButton
             onClick={() => dispatch({type: SET_MODAL_CASH_REQUESTS})}
        >
          <ListItemIcon
          >
            <TaskAltIcon  className='text-white' />
          </ListItemIcon>
          <ListItemText primary="Cash Requests" />
        </ListItemButton>
        {user.role !== 'user' && 
        <>
        <ListItemButton
        onClick={() => dispatch({type: SET_MODAL, payload: 'members'})}
   >
     <ListItemIcon
     >
       <Groups2Icon className='text-white' />

     </ListItemIcon>
     <ListItemText primary={<>Members</>} />
   </ListItemButton>
       <ListItemButton
             onClick={() => dispatch({type: SET_MODAL_USERS})}
        >
          <ListItemIcon
          >
          <ManageAccountsIcon className='text-warning' />
          </ListItemIcon>
          <ListItemText primary={<>Manage Accounts&nbsp;&nbsp;&nbsp;{approvals.length ? <strong className='text-warning'>( {approvals.length} )</strong> : ''}</>} />
        </ListItemButton>
        </>
      }
      {user.admin && 
        <ListItemButton
              onClick={() => dispatch({type: SET_MODAL, payload: 'cashRequestsList'})}
         >
           <ListItemIcon
           >
             <PaymentsIcon className='text-warning' />
           </ListItemIcon>
           <ListItemText primary={<>Cash In/Out Requests&nbsp;&nbsp;&nbsp;{cashRequests.length ? <strong className='text-warning'>( {cashRequests.length} )</strong> : ''}</>} />
         </ListItemButton>
       }
        <ListItemButton
         onClick={e => {
            dispatch(logout(props.history))
        }}
        
        >
          <ListItemIcon>
            <LogoutIcon  className='text-white' />
          </ListItemIcon>
          <ListItemText primary="Log Out" />
        </ListItemButton>
      </List>
    </Box>
    <br/>
    {/* <Box className='user-profile'>
    <List component="nav" aria-label="main mailbox folders" 
     className="text-white"
    subheader={<div className='p-2 text-gray'>
        Event Manager
          </div>
      }>
        <ListItemButton
          onClick={() => dispatch({type: SET_MODAL_SETTINGS})}
        >
          <ListItemIcon>
            <DriveFileRenameOutlineIcon className='text-white' />
          </ListItemIcon>
          <ListItemText primary="Edit Account" />
        </ListItemButton>
        <ListItemButton
                  onClick={() => dispatch({type: SET_MODAL_PROMO})}
        >
          <ListItemIcon>
            <RedeemIcon  className='text-white' />
          </ListItemIcon>
          <ListItemText primary="Promo" />
        </ListItemButton>
        <ListItemButton
        onClick={() => dispatch({type: SET_MODAL_TRANSACTIONS})}
        >
          <ListItemIcon>
            <SubjectIcon  className='text-white' />
          </ListItemIcon>
          <ListItemText primary="Transactions" />
        </ListItemButton>
        <ListItemButton
             onClick={() => dispatch({type: SET_MODAL_CASH_REQUESTS})}
        >
          <ListItemIcon
          >
            <TaskAltIcon  className='text-white' />
          </ListItemIcon>
          <ListItemText primary="Cashin Requests" />
        </ListItemButton>
        <ListItemButton
         onClick={e => {
            dispatch(logout(props.history))
        }}
        >
          <ListItemIcon>
            <LogoutIcon  className='text-white' />
          </ListItemIcon>
          <ListItemText primary="Log Out" />
        </ListItemButton>
      </List>
    </Box> */}
    <Box className='user-profile'>

 <div className='user-actions'>
<button className='btn-contact p-1' onClick={() => dispatch({type: SET_MODAL_SUPPORT})}><MessageIcon/> Contact Us</button>
</div>
</Box>
</div>
    </>
  );
}


export default Sidebar;