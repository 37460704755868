import React, { useState, useRef, useEffect } from 'react'
import ReactPlayer from "react-player";
import moment from 'moment';
import { useDispatch, useSelector} from 'react-redux';
import { SET_STREAM, SET_STREAMING } from '../redux/actions/types';
import PlayBtn from '../assets/playbutton.png';
import commonData from '../utils/commonData';

import { CircularProgress } from '@mui/material';


export default function VideoPlayer({url, stream, timelapse}) {
  const dispatch = useDispatch();
  const [isReady, setIsReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);



  const handlePlay = React.useCallback(() => {

    dispatch({type: SET_STREAMING, payload: {isPlaying: !stream.isPlaying}})
    
    }, [stream]);





  const onReady = React.useCallback(() => {
  
    if (!isReady) {
    
     
      setIsReady(true);
      setIsPlaying(true)
      // dispatch({type: SET_ STREAMING, payload: {isPlaying: !stream.isPlaying}})
    }
  
  }, [isReady]);


let id = "cfc2024";
  
  return (
     <>
     <div style={{padding: 0, margin: 0}} onClick={() => {
        handlePlay()
     } } >
      <div className='video-container' 
      onClick={() => {
        handlePlay()
     } }
      >
  
  <ReactPlayer
         ref={videoRef}
        autoPlay={false}
        muted={stream.isMuted}
        width="100%"
        height="95%"
        controls={stream.controls}
        playing={stream.isPlaying}
        url={`${commonData.streamUrl}/${id}.flv`}
        // url={url}
        playsInline
        onReady={() => onReady()}
            />
</div>


  { ( !stream.isPlaying || !isReady ) &&
        <div className="overlay-play-button__overlay"  >
            {!isReady ? 
          <CircularProgress/>
              :
        <div className="overlay-play-button__play">
          <img src={PlayBtn} height="100%" width="100%" />
        </div>
        }
        </div>  
        }
        </div>
         </>
  )
}
