import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Dialog, IconButton, TextField, InputAdornment, DialogTitle, DialogContent, Typography, Avatar, Box, Grid, Button } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import { CLOSE_MODALS, SET_LOADING, STOP_LOADING } from '../../redux/actions/types';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { configs } from '../../utils/config';
import AttachFileIcon from '@mui/icons-material/AttachFile';

// Icons
import CloseIcon from '@mui/icons-material/Close';
import MessageIcon from '@mui/icons-material/Message';
import SendIcon from '@mui/icons-material/Send';
import moment from 'moment';
import axios from 'axios';
import commonData from '../../utils/commonData';
import { authHeader } from '../../redux/auth-header';
import { COLORS } from '../../constants/Theme';

import ImageViewer from 'react-simple-image-viewer';
import { icons } from '../../constants';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(0),
    bottom: theme.spacing(6)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="small"
          sx={{
            position: 'absolute',
            right: 4,
            top: 4,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs() {
  const { isWeb, supportModal, loading } = useSelector(({ uiReducer }) => uiReducer);
  const scrollableRef = useRef(null);
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }) => auth);
  const agentSrc = 'http://swc.sharewin.pro/static/media/profile.39ca8cc30338a620d7a9.jpg';
  const [fileName, setFileName] = useState(null);
  const [error, setError] = useState(null);

  const [values, setValues] = useState({});
  const [rnd, setRnd] = useState(0);
  const [hidden, setHidden] = useState(true);
  const [messageId, setMessageId] = useState();
  const [messages, setMessages] = useState([]);
  const userRole = user.role;
  const [selected, setSelected] = useState()
  const [selectedName, setSelectedName] = useState({})

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [images, setImages] = useState([]);

  console.log(user, 'USERR')


  const handleFileChange = async (event) => {
    let file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);



    if (file) {
      axios.post(configs.file_url + `/upload/${configs.uploadId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${configs.file_token}`
        },
      }).then(res => {
        console.log('FILE', res.data)
        setValues({ ...values, attachmentUrl: `${configs.file_url}/download/${res.data._id}` })
        setFileName(`${res.data.name}`)
      })
        .catch(err => {
          setError('Something went wrong!')
        })
    }

  }


  const handleClose = () => {
    setSelected(null)
    dispatch({ type: CLOSE_MODALS });
    setFileName(null)
  };

  const handleChanges = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSendMessage = () => {
    if (values.message || values.attachmentUrl) {

      if (!user.admin) {
        axios.post(`${commonData.apiUrl}/chats`, { message: values.message, attachmentUrl: values.attachmentUrl },
          { headers: authHeader() })
          .then(res => {
            console.log(res, "CONSOLE RESPONSE")
            handleGetMessages()
            setValues({ ...values, message: '', attachmentUrl: '' });
            setFileName(null)
          })
          .catch(err => { console.log(err, "Error Response!") })
      } else {

        axios.post(`${commonData.apiUrl}/chats`, { message: values.message, attachmentUrl: values.attachmentUrl, receiverId: selected.senderId, _id: selected._id },
          { headers: authHeader() })
          .then(res => {
            console.log(JSON.stringify(res.data.d), "CONSOLE RESPONSE")
            setValues({ ...values, message: '', attachmentUrl: '' });
            setFileName(null)
            handleGetMessages()
          })
          .catch(err => { console.log(err, "Error Response!") })
      }
    }
  };

  const handleGetMessages = () => {
    try {
      if (user.admin) {
        console.log('GET ADMIN MSS')
        axios.get(`${commonData.apiUrl}/admin/chats`, {
          headers: authHeader()
        })
          .then(response => {
            // Handle success
            console.log(response.data.d, "RESPONDE");
            dispatch({ type: STOP_LOADING })
            setMessages(response.data.d)
            handleImages(response.data.d)
            setRnd(Math.random())
            return
          })
          .catch(error => {
            // Handle error
            console.error(error);
            dispatch({ type: STOP_LOADING })
            setMessages([])
            handleImages([])
            return
          });

      } else {
        console.log('GET PLAYER MSS')

        axios.get(`${commonData.apiUrl}/chats`, {
          headers: authHeader()
        })
          .then(response => {
            // Handle success
            console.log(response.data.d, "RESPONDE");
            dispatch({ type: STOP_LOADING })
            setMessages(response.data.d)
            handleImages(response.data.d)
            setRnd(Math.random())
            return

          })
          .catch(error => {
            // Handle error
            console.error(error);
            dispatch({ type: STOP_LOADING })
            setMessages([])
            handleImages([])
            return
          });
      }
    } catch (err) {
      console.log(err, "Catch error")
      return
    }
  }

  const handleSelected = (item) => {
    if (selected) {
      setSelected(null)
      setSelectedName({})
    }
    setSelected(item)
    setSelectedName(item)

  }


  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  const handleMouseHovered = (index) => {
    setHidden(false);
    setMessageId(index)
  }

  const handleImages = (data) => {
    let images = []
    // setImages(data)
    if (data.length > 0) {
      data[0].conversations.map((item, index) => {
        if (item.attachmentUrl !== '') {
          images.push(item.attachmentUrl)
        }
        return item
      })
      setImages(images)
    }

  }

  const openImageViewer = React.useCallback((data, index) => {
    console.log(data, "DATA PROPS HERE!")
    images.map((img, imgIndex) => {
      if (index == img) {
        setCurrentImage(imgIndex);
      }
      return img
    })
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  useEffect(() => {

    if (scrollableRef.current) {
      scrollableRef.current.scrollTop = scrollableRef.current.scrollHeight;
    }

    console.log(scrollableRef.current, "WEWS")
  }, [selected?.conversations, messages[0]?.conversations]);

  useEffect(() => {
    dispatch({ type: SET_LOADING })
    setSelected(null)
    handleGetMessages()
    
    setTimeout(() => {
      // dispatch({ type: STOP_LOADING })
      console.log("Set time-out 3sec.")
    }, 3000)
    
  }, [])


  console.log(messages, 'MESSage')


  let selectedMsg = messages.find(a => a._id == selected?._id);
  console.log(messages, 'MESSage', selectedMsg)


  console.log(loading, images, "IMAGE SET TO ARRAY!")

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={supportModal}
      maxWidth="sm"
      maxHeight={50}
      minHeight={30}
      fullWidth
      fullScreen={!isWeb}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} className="bg-dark-blue text-white text-15">
        <MessageIcon sx={{ mr: 1 }} /> Chat Support
      </BootstrapDialogTitle>
      <DialogContent display="flex" alignItems="flex-start" className="dialog-content text-white">

        {
          user.admin ?

            <div style={{ display: 'flex', flexDirection: 'column', }}>
              {
                selected !== null ?
                  (
                    <>
                      <Box style={{ width: '100%', position: 'sticky', top: 0, backgroundColor: 'rgb(26, 33, 54)', padding: 14, zIndex: 1, display: 'flex', flexDirection: 'row' }}>
                        <ArrowBackIosIcon style={{ width: 25, height: 25, alignSelf: 'center' }}
                          onClick={() => {
                            setSelected(null);
                            setSelectedName({});
                          }}
                        />
                        <Avatar src={agentSrc} sizes='50px' sx={{ bgcolor: 'gray.main', mr: 2 }}>A</Avatar>
                        <div style={{ flexDirection: 'column' }}>
                          <Typography color={COLORS.white} sx={{ fontSize: 18 }}>
                            {String(selectedName?.senderId?.username).charAt(0).toUpperCase() + String(selectedName?.senderId?.username).substring(1).toLowerCase()} {selectedName?.senderId?.role === 'user' ? '(Player)' : selectedName?.senderId?.role === 'agent' ? '(Agent)' : null}
                          </Typography>
                          <Typography style={{ fontSize: 10 }}>
                            {'Active'}
                          </Typography>
                        </div>
                      </Box>
                      <Typography sx={{ textAlign: 'center' }}>
                        {moment(messages[0]?.createdAt).format('MM/DD/YY')}
                      </Typography>
                      <div ref={scrollableRef} style={{ display: 'flex', marginTop: 4, overflowY: 'auto', flexDirection: 'column', }}>
                        <style>
                          {`
            /* Chrome, Safari and Opera */
            div::-webkit-scrollbar {
              display: none;
            }
          `}
                        </style>
                        {selectedMsg?.conversations?.map((data, index) => (
                          <div
                            key={index}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              flexDirection: 'row',
                              paddingLeft: 8,
                              paddingRight: 8,
                              justifyContent: data.sender === user.userId ? 'flex-end' : 'flex-start',
                            }}
                          >
                            {data.sender !== user.userId && <Avatar src={agentSrc} sizes='50px' sx={{ bgcolor: 'gray.main', mr: 1 }}>A</Avatar>}
                            {
                              hidden ? null : !hidden && index === messageId && data.sender === user.userId ?
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: data.sender === user.userId ? 'flex-end' : 'flex-start', }}>
                                  <Typography sx={{ fontSize: 10, }}>
                                    {moment(data.createdAt).format('h:mm A')}
                                  </Typography>
                                </div>
                                :
                                null
                            }
                            <Box
                              onMouseEnter={() => handleMouseHovered(index)}
                              onMouseLeave={() => setHidden(true)}
                              m={1}
                              mb={4}
                              style={{
                                maxWidth: '70%',
                                minWidth: '15%',
                                // flexBasis: 1,
                                // paddingRight: 10,
                                minHeight: "50px",
                                maxHeight: "350px",
                                borderTopRightRadius: data.sender === user.userId ? 18 : 18,
                                borderTopLeftRadius: data.sender === user.userId ? 18 : 18,
                                borderBottomLeftRadius: data.sender === user.userId ? 18 : 0,
                                borderBottomRightRadius: data.sender !== user.userId ? 18 : 0,
                                backgroundColor: data.sender === user.userId ? COLORS.secondary : COLORS.black800,
                                // display: 'flex', 


                                // alignItems: 'flex-start',
                                flexDirection: 'column',
                                // justifyContent: 'space-between'
                              }}
                            >
                              <Typography
                                // gutterBottom={true}
                                style={{
                                  whiteSpace: 'normal',  // Allow text to wrap
                                  wordBreak: 'break-word',  // Optional: Breaks long words to fit within the container
                                  paddingLeft: 10,
                                  marginTop: '4px',
                                  overflowY: 'hidden',
                                  paddingRight: 10,
                                }}>
                                {data.message}
                              </Typography>
                              <div >
                                {
                                  data.attachmentUrl &&
                                  <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    
                          
                          

                                    <img
                                      src={loading ? icons.loader : data?.attachmentUrl}
                                      onClick={() => openImageViewer(data)}
                                      // onClick={ () => console.log(index, "INDEX HERE!")}
                                      width={loading ? 20 : "100%"}
                                      height={loading ? 20 : "150"}
                                      key={index}
                                      style={{ marginTop: '4px', backgroundColor: data.sender === user.userId ? COLORS.secondary : COLORS.black800 }}
                                    />

                                  </div>
                                }
                                {isViewerOpen && (
                                <>
                                  <ImageViewer
                                    src={images}
                                    currentIndex={currentImage}
                                    disableScroll={true}
                                    closeOnClickOutside={true}
                                    onClose={closeImageViewer}
                                  />
                                </>
                                )}
                              </div>
                            </Box>
                            {
                              hidden ? null : !hidden && index === messageId && data.sender !== user.userId ?
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: data.sender === user.userId ? 'flex-end' : 'flex-start', }}>

                                  {/* <div style={{display: 'flex', alignSelf: "center", justifyContent: 'center', bottom: 10, borderRadius: 999, height: 10, backgroundColor: COLORS.gray500, width: 10, border: '1px solid gray', marginLeft: 4, marginRight: 4}}>
                    {
                      data.isViewed === true ? 

                      <Avatar src={agentSrc} sx={{height: 10, width: 10, alignSelf: 'center'}}>A</Avatar>
                      :
                      null
                      
                    }

                  </div> */}

                                  <Typography sx={{ fontSize: 10, }}>
                                    {moment(data.createdAt).format('h:mm A')}
                                  </Typography>
                                </div>
                                :
                                null
                            }
                            {data.sender === user.userId && <Avatar src={agentSrc} sizes='50px' sx={{ bgcolor: 'gray.main', ml: 1 }}>A</Avatar>}
                          </div>
                        ))}

                      </div>
                      {/* <div style={{ display: 'flex', position: 'sticky', alignItems: 'flex-end', justifyContent: 'flex-end', border: '1px solid blue', zIndex: 0, width: '100%' }}>
          <DialogContent alignItems="flex-end" sx={{ backgroundColor: 'white' }} className="dialog-content text-white h-600">
            
          </DialogContent>

        </div> */}
                    </>

                  )
                  :
                  (


                    messages.map((item, index) => {
                      let name = item.senderId?.username
                      return (
                        <>

                          <div fullWidth={true} onClick={(e) => handleSelected(item)} className="clickable" style={{ display: 'flex', padding: 10, marginBottom: 6, flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center', borderBottom: '.5px solid #7b898f' }}>
                            <Avatar src={agentSrc} sizes='50px' sx={{ bgcolor: 'gray.main', mr: 2 }}>A</Avatar>
                            <div style={{ flexDirection: 'column' }}>
                              <Typography color={COLORS.white} sx={{ fontSize: 18, textAlign: 'left' }}>
                                {String(name).charAt(0).toUpperCase() + String(name).substring(1).toLowerCase()}
                              </Typography>
                              <Typography noWrap={true} sx={{ fontSize: 10, textAlign: 'left' }}>
                                {item.conversations[item.conversations.length - 1].message}
                              </Typography>
                            </div>
                            <Typography style={{ width: '100%', textAlign: 'right' }}>
                              {moment(item.createdAt).format('MM/DD/YY')}
                            </Typography>
                          </div>

                        </>
                      )
                    }

                    )
                  )
              }
            </div>

            :
            <>
              <Box display='flex' alignItems="center" justifyContent="flex-start" sx={{ position: 'sticky', top: 0, width: '100%', padding: 2, maxHeight: 90, backgroundColor: 'rgb(26, 33, 54)', zIndex: 1 }}>
                <Avatar src={'https://ih1.redbubble.net/image.5270566593.1363/flat,750x,075,f-pad,750x1000,f8f8f8.u1.jpg'} sizes='40px' sx={{ bgcolor: 'gray.main', mr: 2 }}>A</Avatar>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="body">
                    {messages && (messages[0]?.receiverId === undefined || messages[0]?.receiverId === null)
                      ?
                      'Customer Support'
                      :
                      String(messages[0]?.receiverId?.username).charAt(0).toUpperCase()
                      +
                      String(messages[0]?.receiverId?.username).substring(1)
                      +
                      String(messages[0]?.receiverId?.role === 'agent' && messages[0]?.receiverId?.isAdmin === true ? ' (Admin)' : messages[0]?.receiverId?.role === 'user' ? '(Player)' : messages[0]?.receiverId?.role === 'agent' ? '(Agent)' : null)
                    }</Typography>
                  <Typography sx={{ fontSize: 12 }}>
                    {/* socketActive ? 'Active' : 'Offline' */} Active
                  </Typography>
                </div>
              </Box>
              <Box
                ref={scrollableRef}
                sx={{ padding: 1, }}
              >
                <style>
                  {`
            /* Webkit Browsers */
            div::-webkit-scrollbar {
              width: 2px;
            }
            div::-webkit-scrollbar-thumb {
              background-color: ${COLORS.primary};
              border-radius: 10px;
            }
            div::-webkit-scrollbar-track {
              background-color: ${COLORS.secondary};
              border-radius: 10px;
            }
          `}
                </style>

                {
                  messages[0]?.createdAt &&
                  <Typography sx={{ textAlign: 'center' }}>
                    {moment(messages[0]?.createdAt).format('MM/DD/YY')}
                  </Typography>
                }

                {messages[0]?.conversations.map((chat, index) => {
                  return (
                    <>
                      <Box
                        key={index}
                        display="flex"
                        justifyContent={chat.sender === user.userId ? 'flex-end' : 'flex-start'}
                        alignItems={'center'}
                        // paddingTop={10}
                        // mb={15}
                        maxHeight="200px"
                        minHeight='50px'
                        sx={{ width: '100%' }}
                      >
                        {
                          chat.sender !== user.userId ?
                            <>
                              <Avatar src={agentSrc} sx={{ bgcolor: 'primary.main', mr: 1 }}>A</Avatar>
                              <Box
                                m={1}
                                bgcolor={chat.sender === user.userId ? COLORS.secondary : 'grey.300'}
                                color={chat.sender === user.userId ? 'white' : 'black'}
                                style={{ borderTopRightRadius: 10, borderTopLeftRadius: 10, borderBottomRightRadius: 10 }}
                                maxWidth="75%"
                                minHeight="20px"
                                maxHeight="200px"
                                minWidth='15%'
                                onMouseEnter={() => handleMouseHovered(index)}
                                onMouseLeave={() => setHidden(true)}
                              >
                                <Typography
                                  // sx={{ fontSize: 14, textAlign: 'left', padding: 1}}
                                  // gutterBottom={true}
                                  style={{
                                    whiteSpace: 'normal',  // Allow text to wrap
                                    wordBreak: 'break-word',  // Optional: Breaks long words to fit within the container
                                    paddingLeft: 10,
                                    marginTop: '4px',
                                    overflowY: 'hidden',
                                    justifyContent: 'center',
                                    textAlign: 'start',
                                    paddingRight: 10,
                                  }}
                                >
                                  {chat.message}
                                </Typography>
                                <div >
                                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', minHeight: 30, minWidth: 30 }}>
                                  {
                                    chat.attachmentUrl &&
                                      <img
                                        src={loading ? icons.loader : chat?.attachmentUrl}
                                        onClick={() => openImageViewer(chat?.attachmentUrl)}
                                        // onClick={ () => console.log(index, "INDEX HERE!")}
                                        width={loading ? 20 : "100%"}
                                        height={loading ? 20 : "150"}
                                        key={index}
                                        style={{ marginTop: '4px', backgroundColor: chat.sender === user.userId ? COLORS.secondary : 'grey.300' }}
                                      />

                                    }
                                  {isViewerOpen && (
                                    <>


                                      <ImageViewer 
                                        src={images}
                                        currentIndex={currentImage}
                                        backgroundStyle={{ backgroundColor: COLORS.transparentBlack1 }}
                                        disableScroll={true}
                                        closeOnClickOutside={true}
                                        onClose={closeImageViewer}

                                      />
                                    </>
                                  )}
                                    </div>

                                </div>


                              </Box>
                              {
                                hidden ? null : !hidden && index === messageId &&
                                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-start', }}>
                                    {/* <div style={{display: 'flex', alignSelf: "center", justifyContent: 'center', bottom: 10, borderRadius: 999, height: 10, backgroundColor: COLORS.gray500, width: 10, border: '1px solid gray', marginLeft: 4, marginRight: 4}}>
                    {
                      chat.isViewed === true ? 

                      <Avatar src={agentSrc} sx={{height: 10, width: 10, alignSelf: 'center'}}>A</Avatar>
                      :
                      null
                      
                    }

                  </div> */}
                                    <Typography sx={{ fontSize: 10, }}>
                                      {moment(chat.createdAt).format('h:mm A')}
                                    </Typography>
                                  </div>
                              }
                            </>
                            :
                            chat.sender === user.userId ?
                              <>
                                {
                                  hidden ? null : !hidden && index === messageId &&
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-end', }}>
                                      <Typography sx={{ fontSize: 10, }}>
                                        {moment(chat.createdAt).format('h:mm A')}
                                      </Typography>
                                      {/* <div style={{display: 'flex', alignSelf: "center", justifyContent: 'center', bottom: 10, borderRadius: 999, height: 10, backgroundColor: COLORS.gray500, width: 10, border: '1px solid gray', marginLeft: 4, marginRight: 4}}>
                    {
                      chat.isViewed === true ? 

                      <Avatar src={agentSrc} sx={{height: 10, width: 10, alignSelf: 'center'}}>A</Avatar>
                      :
                      null
                      
                    }

                  </div> */}
                                    </div>
                                }
                                <Box
                                  m={1}
                                  bgcolor={chat.sender === user.userId ? COLORS.secondary : 'grey.300'}
                                  color={chat.sender === user.userId ? 'white' : 'black'}
                                  style={{ borderTopRightRadius: 10, borderTopLeftRadius: 10, borderBottomRightRadius: 10 }}
                                  maxWidth="75%"
                                  minHeight="50px"
                                  maxHeight="200px"
                                  minWidth='15%'
                                  onMouseEnter={() => handleMouseHovered(index)}
                                  onMouseLeave={() => setHidden(true)}
                                >
                                  <Typography
                                    // sx={{ fontSize: 14, textAlign: 'left', padding: 1}}
                                    // gutterBottom={true}
                                    style={{
                                      whiteSpace: 'normal',  // Allow text to wrap
                                      wordBreak: 'break-word',  // Optional: Breaks long words to fit within the container
                                      paddingLeft: 10,
                                      paddingTop: 5,
                                      marginTop: '4px',
                                      textAlign: 'start',
                                      
                                      overflowY: 'hidden',
                                      paddingRight: 10,
                                    }}
                                  >
                                    {chat.message}
                                  </Typography>
                                  <div >
                                    {
                                      chat.attachmentUrl &&
                                      <div style={{ flexDirection: 'row', padding: loading ? 10 : 0, display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 30, minWidth: 30}}>
                                        <img
                                          src={loading ? icons.loader : chat?.attachmentUrl}
                                          onClick={() => openImageViewer(chat?.attachmentUrl)}
                                          // onClick={ () => console.log(index, "INDEX HERE!")}
                                          width={loading ? 20 : "100%"}
                                          height={loading ? 20 :"150"}
                                          key={index}
                                          style={{ marginTop: '4px', backgroundColor: chat.sender === user.userId ? COLORS.secondary : COLORS.black800  }}
                                        />

                                      </div>
                                    }
                                    {isViewerOpen && (
                                      <>
                                        <ImageViewer
                                          src={images}
                                          currentIndex={currentImage}
                                          backgroundStyle={{ backgroundColor: COLORS.transparentBlack1 }}
                                          disableScroll={true}
                                          closeOnClickOutside={true}
                                          onClose={closeImageViewer}

                                        />
                                      </>
                                    )}

                                  </div>


                                </Box>
                                <Avatar src={agentSrc} sizes='40px' sx={{ bgcolor: 'primary.main', ml: 1, fontSize: 24, alignItems: 'center', justifyContent: 'center' }}>{String(user?.username).charAt(0)}</Avatar>
                              </>
                              :
                              null
                        }

                      </Box>

                    </>
                  )
                })}
              </Box>
            </>
        }
      </DialogContent>
      {user.admin && selected ?
        (
          <div
            style={{ display: 'flex', alignSelf: 'flex-end', width: '100%', flexDirection: 'column' }}

          >
            {fileName && <Box className="bg-dark-blue mr-2 ml-2 w-100" style={{ paddingLeft: '10px', paddingRight: '10px' }}><strong className="mr-2">Attachment:&nbsp;&nbsp;</strong><a href={values.attachmentUrl} target="_blank" className="text-white">{fileName}</a></Box>}
            <TextField
              fullWidth
              placeholder="Type your message..."
              value={values.message || ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton color="primary" aria-label="upload picture" component="label" className='text-bold' edge="end">
                      <AttachFileIcon color="primary" />
                      <input hidden accept="image/*" type="file" onChange={handleFileChange} />
                    </IconButton>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <IconButton edge="end" onClick={handleSendMessage}>
                      <SendIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={handleChanges('message')}
              onKeyPress={handleKeyPress}
            />
          </div>

        )
        :
        null
      }

      {
        !user.admin &&
        <>
          {fileName && <Box className="bg-dark-blue mr-2 ml-2 w-100" style={{ paddingLeft: '10px', paddingRight: '10px' }}><strong className="mr-2">Attachment:&nbsp;&nbsp;</strong><a href={values.attachmentUrl} target="_blank" className="text-white">{fileName}</a></Box>}
          <TextField
            fullWidth
            placeholder="Type your message..."
            value={values.message || ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton color="primary" aria-label="upload picture" component="label" className='text-bold' edge="end">
                    <AttachFileIcon color="primary" />
                    <input hidden accept="image/*" type="file" onChange={handleFileChange} onSubm={handleFileChange}/>
                  </IconButton>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <IconButton edge="end" onClick={handleSendMessage}>
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
            onChange={handleChanges('message')}
            onKeyPress={handleKeyPress}
          />
        </>
      }
    </BootstrapDialog>
  );
}