import * as React from 'react';
import { useState, useEffect } from 'react';
//Icons
import { useDispatch, useSelector } from 'react-redux';
import { SET_MODAL_BET } from '../redux/actions/types';
import CountUp from 'react-countup';

import { getDecimalPlace } from '../utils/helpers';



export default function RecipeReviewCard() {
    const dispatch = useDispatch();
    const [lastBets, setLastBets] = useState({
      meron: 0,
      wala: 0,
      draw: 0,
      meronRate: 0,
      walaRate: 0,
      drawRate: 0
    });
    const [lastBet, setLastBet] = useState({
      meron: 0,
      wala: 0,
      draw: 0,
      meronRate: 0,
      walaRate: 0,
      drawRate: 0
    });
    const { currentFight, bets, bet} = useSelector(({betting}) => betting);


  let isOpen =  currentFight.fightStatus === "open" || currentFight.fightStatus === "lastCall" ? true : false;

useEffect(() => {
    // start()
    // update(bets.meron)
    return () => {
      setLastBets({...lastBets, ...bets})
    }
}, [currentFight.fightStatus, bets])


useEffect(() => {
  // start()
  // update(bets.meron)
  return () => {
    setLastBet({...lastBet, ...bet})
  }
}, [currentFight.fightStatus, bet])

useEffect(() => {
  // start()
  // update(bets.meron)
  if(currentFight.fightStatus == 'end'){
      setLastBet({ 
      meron: 0,
      wala: 0,
      draw: 0,
      meronRate: 0,
      walaRate: 0,
      drawRate: 0
      })  
      setLastBets({ 
        meron: 0,
        wala: 0,
        draw: 0,
        meronRate: 0,
        walaRate: 0,
        drawRate: 0
        })  
  
  }
  

}, [currentFight.fightStatus])



let payoutWala = bets.walaRate * bet.wala;
let payoutMeron = bets.meronRate * bet.meron;
let payoutDraw = bets.drawRate * bet.draw;



  return (
    <div className='betting-wrapper'>
    <div className='bet-container'>
    <button className={`border-0 w-100 bet-meron ${!isOpen ? "opacity-6" : ""}`}  disabled={!isOpen}  onClick={() => dispatch({type: SET_MODAL_BET, payload: "meron"})}>
    <strong className='text-bolder'>
    MERON
    </strong>
    </button>
    <div className='p-3' >
    <div className='bets-text' >
    <CountUp start={Number(lastBets?.meron).toFixed(2)} end={Number(bets?.meron).toFixed(2)} /><small>.{getDecimalPlace(Number(bets?.wala).toFixed(2))}</small>
    </div>
    <div className='payout-text'><CountUp start={Number(lastBets?.meronRate * 100).toFixed(0)} end={Number(bets?.meronRate * 100).toFixed(2)}  />%</div>
    <div className='d-flex justify-content-center align-items-center text-white'>
    <CountUp className="bet-text" end={Number(bet.meron).toFixed(0)}  />
    &nbsp;=&nbsp; 
      <strong className="winning-text">{Number(payoutMeron).toFixed(0)}</strong>
      <small className="decimal-text text-win">.{getDecimalPlace(Number(payoutMeron).toFixed(2))}</small>
    </div>  
    
    <div className={`bet-btn ${isOpen ? "bg-meron" : ""}`}>
    <button className='w-100'
    disabled={!isOpen} 
    onClick={() => dispatch({type: SET_MODAL_BET, payload: "meron"})}><small>{isOpen ? "BET MERON" : "CLOSED"}</small></button>
    </div>
    </div>
    
    </div>

    <div className='bet-container' >
    <button className={`border-0 w-100 bet-draw ${!isOpen ? "opacity-6" : ""}`}  disabled={!isOpen}  onClick={() => dispatch({type: SET_MODAL_BET, payload: "draw"})}>
      <strong className='text-bolder'>
    DRAW
    </strong>
    </button>
    <div className='p-3' >
    <div className='bets-text' ><CountUp start={Number(lastBets?.draw).toFixed(2)} end={Number(bets?.draw).toFixed(2)} /><small>.{getDecimalPlace(Number(bets?.draw).toFixed(2))}</small></div>
    <div className='payout-text'><CountUp start={Number(bets?.drawRate * 100).toFixed(0)} end={Number(bets?.drawRate * 100).toFixed(0)} />%</div>
    <div className='d-flex justify-content-center align-items-center text-white'>
    <CountUp className="bet-text" end={Number(bet.draw).toFixed(0)}  />&nbsp;=&nbsp; 
      <strong className="winning-text">{Number(bet.draw * 8)}</strong><small className="decimal-text text-win">.{getDecimalPlace(Number(bet.draw * 8).toFixed(2))}</small>
    </div> 
    <div className={`bet-btn ${isOpen ? "bg-draw" : ""}`}>
    <button className='w-100' disabled={!isOpen} onClick={() => dispatch({type: SET_MODAL_BET, payload: "draw"})}><small>{isOpen ? "BET DRAW" : "CLOSED"}</small></button>
    </div>
    </div>
    </div>
    <div className='bet-container'>

    <button className={`border-0 w-100 bet-wala ${!isOpen ? "opacity-6" : ""}`}  disabled={!isOpen}  onClick={() => dispatch({type: SET_MODAL_BET, payload: "wala"})}>
    <strong className='text-bolder'>
    WALA
    </strong>
    </button>
    <div className='p-3' >
    <div className='bets-text' ><CountUp start={Number(lastBets?.wala).toFixed(2)} end={Number(bets?.wala).toFixed(2)} /><small>.{getDecimalPlace(Number(bets?.wala).toFixed(2))}</small></div>
    <div className='payout-text'>
    <CountUp start={Number(bets?.walaRate * 100).toFixed(0)} end={Number(bets?.walaRate * 100)}  />%
    </div>
    <div className='d-flex justify-content-center align-items-center text-white'>
    <CountUp className="bet-text" end={Number(bet.wala).toFixed(0)}  />&nbsp;=&nbsp; 
      <strong className="winning-text">{Number(payoutWala).toFixed(0)}</strong><small className="text-win decimal-text">.{getDecimalPlace(Number(payoutWala).toFixed(2))}</small>
    </div> 
    <div className={`bet-btn ${isOpen ? "bg-wala" : ""}`}>
    <button className='w-100' 
    disabled={!isOpen}
    onClick={() => dispatch({type: SET_MODAL_BET, payload: "wala"})}><small>{isOpen ? "BET WALA" : "CLOSED"}</small></button>
    </div>
    </div>
    </div>
    </div>
  );
}
