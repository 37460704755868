import * as React from 'react';
import { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Button, FormControl, FormGroup, FormLabel,  TextField, InputAdornment, IconButton } from '@mui/material';
import { Link } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery';

import './Login.css';
import CFCLOGO from '../../assets/cfc-logo.png'
import MessageIcon from '@mui/icons-material/Message';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../redux/actions/Auth';




export default function SimpleContainer({history}) {
        const dispatch = useDispatch()
        const { user } = useSelector(({auth}) => auth )
        const { errors } = useSelector(({uiReducer}) => uiReducer )
        const [values, setValues] = useState({
        phone: "",
        password: ""
        });
        const [showPassword, setShowPassword] = React.useState(false);
        const [loading, setLoading] = useState(false);
        const isMd = useMediaQuery('(min-width:600px)');

        const handleClickShowPassword = () => setShowPassword((show) => !show);
 
        
    const handleChanges = (prop) => event => {
        setValues({...values, [prop]: event.target.value})
        
    }
    
    
  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true)
    dispatch(loginUser(values, history))
    .then(() => {
      setLoading(false)
    })
    .catch(err => {
    setLoading(false)})
    // dispatch({type: UPDATE_AUTH_USER, payload: { username: 'wewew', phone: '123123123' }})
    // window.location.href = '/sabong'
  }

  
  return (
    <div className='login-main'>
      <CssBaseline />
      <div className={isMd ? 'login-wrapper' : 'login-wrapper-md'}>
      <div className={isMd ? 'banner-wrapper mr-5' : 'banner-wrapper m-5'}>
      <div >
      <img src={CFCLOGO} className='login-logo'/>
      </div>
      </div>
        <div className='form-wrapper'>
        <div className="text-center m-1 mb--3">
        {errors.message && 
              <span
                className="field-validation-valid text-danger"
                data-valmsg-for="InvalidLogin"
                data-valmsg-replace="true"
              > {errors.message}</span>
        }
        </div>
        
        <form onSubmit={handleLogin}>
            <FormControl component="fieldset">
       <FormLabel component="legend" className='text-light input-label'>Mobile Number / Username</FormLabel>
        <FormGroup aria-label="position" row>
            <TextField
                id="outlined-size-small"
                size="small"
                fullWidth
                className='login-input'
                value={values.phone}
                onChange={handleChanges('phone')}
                // helperText={errors.phone && errors.phone}
                // error={errors.phone && errors.phone}
              />
        </FormGroup>
        {errors.phone &&  <div className="error-div small">
                  <span
                    className="field-validation-valid text-danger"
                  > {errors.phone} </span>
                </div>}
      <FormLabel component="legend" className='mt-3 text-light input-label'>Password</FormLabel>
      <FormGroup aria-label="position" row>
      <TextField
          id="outlined-size-small"
          value={values.password}
          onChange={handleChanges('password')}
          size="small"
          fullWidth
          className='login-input'
          type={showPassword ? "text" : "password"}
          
          InputProps={{
            endAdornment:  <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
              className='text-light'
            >
              {showPassword ?  <Visibility /> : <VisibilityOff /> }
            </IconButton>
          </InputAdornment>,
          }}
          // helperText={errors.password && errors.password}
          error={errors.password && errors.password}
        />
      
      </FormGroup>
      {errors.password &&  <div className="error-div small">
                  <span
                    className="field-validation-valid text-danger"
                  > {errors.password} </span>
                </div>}
      
      <FormGroup aria-label="position" row className='mt-3' >
        <Button type="submit" fullWidth variant='contained' disabled={loading} >Login</Button>
      </FormGroup>
    {/*   <FormGroup aria-label="position" row className='mt-3'>
        <Button component={Link} to="/register/user"  fullWidth variant='outlined'  className='border-light text-light'>Register</Button>
      </FormGroup> */}
        {/* <FormGroup aria-label="position" row className='mt-2'>
        <Button size='small' fullWidth className='text-gray'>Forgot Password</Button>
      </FormGroup>
      <FormGroup aria-label="position" row >
        <Button size='small' fullWidth className='text-gray'>
        <MessageIcon fontSize='small'/> &nbsp;&nbsp;
        Contact Customer Support
        </Button>
      </FormGroup>*/}
    </FormControl>
    </form>
    </div>
      </div>
            
      </div>
  );
}