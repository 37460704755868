import React from 'react';
import './CashG.css';
import { COLORS } from '../constants/Theme';
import { icons, images } from '../constants';
import moment from 'moment';



function CashG({ amount, receiver }) {
  
  return (
    <div className="App">
    <div style={{ display: 'flex', padding: 10, flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', width: '90%'}}>
          <div className="back-button" style={{color: COLORS.white, fontWeight: 600}}>X</div>
          <div className="title" style={{color: COLORS.white, fontWeight: 600}}>Express Send</div>
          <div className="title"></div>
    </div>
      <div className="receipt-container" style={{ justifyContent: 'center', alignItems: 'center'}}>
        <div className="icon-container" style={{ position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '90%', top: 60 }}>
            <div className="check-icon" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', border: '4px solid white', fontWeight: 500, borderRadius: 66, width: 66, height: 66, backgroundColor: 'rgba(5, 91, 214)'}}>
              <img 
                src={icons.check}
                style={{
                  height: 30,
                  width: 30,
                  alignSelf: 'center'
                  // background: COLORS.white
                }}
                background={COLORS.white}
                
              />
            </div>
            
          </div>
        <div className="content">

          {/* <div className="details"> */}
            <div className="name" style={{ wordSpacing: 4}}>JO•N BE•••••T G.</div>
            <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
              <div 
                  style={{
                      fontWeight: '600',
                      fontSize: 14,
                      backgroundColor: COLORS.info200,
                      wordSpacing: 0,
                      width: '50%',
                      alignSelf: 'center',
                      color: `rgba(2, 54, 153)`,
                      borderRadius: 22
                      // marginTop: 40
                    }}
                    >
                  +63 977 446 1641
                </div>
            </div>
            <div className="subtext">Sent via GCash</div>
        <div style={{ border: '.01px solid lightGray'}}/>
        
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingTop: 8, paddingBottom: 8}}>
            <div className="amount-label" style={{ color: `rgba(11, 29, 69)`, fontWeight: 600}}>Amount</div>
            <div className="amount" style={{ color: `rgba(11, 29, 69)`, fontWeight: 600}}>₱200.00</div>
        </div>
        
        <div style={{ border: '.01px solid lightGray'}}/>
        
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingTop: 8, paddingBottom: 8}}>
            <div className="total-label" style={{ color: `rgba(11, 29, 69)`, fontWeight: 600}}>Total Amount Sent</div>
            <div className="total-amount" style={{ color: `rgba(11, 29, 69)`, fontWeight: 600}}>₱200.00</div>
        </div>
          {/* </div> */}
        </div>
        <div className="footer" style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'column', height: '280px', marginTop: 40 }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between',}}>
          <div style={{ display: 'flex', flexDirection: 'row', width: '60%'}}>
          <div className="reference" style={{ color: `rgba(11, 29, 69, 0.6)`, fontWeight: 600, width: '30%', textAlign: 'left'}}>Ref No.</div>
          <div style={{ color: `rgba(11, 29, 69)`, fontWeight: 600, width: '70%', textAlign: 'left'}}>4019 314 049137</div>
          </div>
          <div className="date" style={{ color: `rgba(11, 29, 69)`, fontWeight: 600}}>Jul 20, 2024 9:11 PM</div>
        </div>
        <div style={{ padding: 8, backgroundColor: `rgb(157, 234, 198)`, height: 100, borderRadius: 4, borderLeft: '5px solid #2cc393'}}> 
            <div className="carbon-footprint">
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', width: '100%'}}>
                <img 
                  src={icons.eco}
                  style={{
                    height: 30,
                    width: 30
                  }}
                />
                <div className="footprint-value" style={{ color: '#18744b', fontWeight: 600, paddingTop: '5%'}}>131g (gCO2e)</div>
            </div>
                <div className="footprint-text" style={{ color: '#147c4b', fontWeight: 600, textAlign: 'left', paddingLeft: '1%'}}>By going digital, you reduce your carbon footprint from transpo, paper, and plastic.</div>
            </div>
        </div>
        </div>
      </div>
      {/* <img src={images.thorny} style={{ display: 'flex', alignSelf: 'center', width: '99%', height: 10, left: 2, position: 'sticky', bottom: 52, opacity: 10}}/> */}
    </div>
  );
}

export default CashG;
