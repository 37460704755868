import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';

//Components
import Blog from './pages/blog/Blog';
import StreamPage from './components/StreamPlayer';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';

//Redux
import { history } from './redux/store';
import { getUserData} from "./redux/actions/Auth";
import {  useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import TestPage from "./Test";
import CashG from "./pages/CashG";



const theme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1200,
    }
  }
});





const ProtectedRoute = ({ isAuthUser, children }) => {
  const token = localStorage.idToken;

  if (!token && !isAuthUser) {
    return <Navigate to="/login" replace />;
  }

  return children;
};


const PublicRoute = ({ isAuthUser, children }) => {

  if (isAuthUser) {
    return <Navigate to="/" replace />;
  }

  return children;
};


function App() {
const dispatch = useDispatch();
  const { isAuthUser } = useSelector(({auth}) => auth);
  
useEffect(() => {
  const token = localStorage.idToken;
  if (token) {
    dispatch(getUserData())
  }
  

  
}, [isAuthUser]);



useEffect(() => {

// Disable right-click
document.addEventListener('contextmenu', (e) => e.preventDefault());

function ctrlShiftKey(e, keyCode) {
  return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
}

document.onkeydown = (e) => {
  // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
  if (
    e.keyCode === 123 ||
    ctrlShiftKey(e, 'I') ||
    ctrlShiftKey(e, 'J') ||
    ctrlShiftKey(e, 'C') ||
    (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
  )
    return false;
};
}, [])

  
  return (
    <ThemeProvider theme={theme}>
        <Router
            history={history}
        >
              <Routes>
                <Route path="/register/:rid" element={<Register />} />
                <Route path="/stream" element={<StreamPage />} /> 
                <Route path="/test" element={<TestPage />} /> 
                <Route path="/CashG" element={<CashG />} /> 
                <Route path="login" element={<PublicRoute isAuthUser={isAuthUser}>
                    <Login/>              
                </PublicRoute>} />
                <Route path="*" element={<ProtectedRoute isAuthUser={isAuthUser}>
                    <Blog/>              
                </ProtectedRoute>
                } /> 
              </Routes>
        </Router>
        </ThemeProvider>
  );
}

export default App;
