

export const formatDec = (val) => {
    return Math.round((val) * 1e12) / 1e12;
};

export const formatTime = (val) => {
    let hours = val.split(':')[0];
    let minutes = val.split(':')[1];
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;

    return strTime
}

export const groupResults = (data, row) => {
    let newArr = [];
    let rowCount = 0;
 
    for(let val of data){
        newArr[rowCount] = newArr[rowCount] ? [val, ...newArr[rowCount]].sort(function(a, b) {
            return a.fightNo - b.fightNo;
          }) : [val];
        if(rowCount === row){
            rowCount = 0;
        } else {
            rowCount++
        }
    }
    return newArr
}

export const groupResultsmaxWin = (data) => {
    let newArr = [];
    let lastVal = null;
    let maxCol = 1;
    let cols = 1;
    let totalFights = data[data.length - 1] ? data[data.length - 1].fightNo : 0;
    let newData = [];

    for(let i = 0; i < totalFights; i++ ){
    let val = data.find(a => a.fightNo === i + 1);
    if(!val){
        newData.push({fightNo: i + 1, win: 'cancelled'})
    } else {
        newData.push(val)
    }
    }



    for(let val of newData){
        if(lastVal && lastVal.win === val.win){
            if(maxCol < cols && cols > 1){
                maxCol++
                newArr[cols] = newArr[cols] ? [...newArr[cols], {...val, index: newArr[0].length - 1}] :  [{...val, index: newArr[0].length - 1}]
            } else {
                newArr[cols] = newArr[cols] ? [...newArr[cols], {...val, index: newArr[0].length - 1}] : [{...val, index: newArr[0].length - 1}];
            }
            cols++ 

        } else {
            if(newArr[0]){
               newArr[0].push({...val, index: newArr[0].length - 1})
            } else {

                newArr = [[{...val, index: 0}]];
            }
            cols = 1;
        }
        lastVal = val;
    }
    
    
    if(newArr.length !== 0){
        newArr.push([])
        newArr.push([])
    }
    return newArr
}


export const getDecimalPlace = (num) => {
  let newNum = (num+"").split(".")[1]


    
    return newNum

}

