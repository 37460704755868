import React, { useEffect, useState } from 'react'
import { 
    Button,
    Divider,
     Typography
} from '@mui/material';
import { groupResultsmaxWin } from '../utils/helpers';

//Components
import TrendsTable from './TrendsTable';


import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useDispatch, useSelector } from 'react-redux';
import { SET_MODAL_POINTS } from '../redux/actions/types';

export default function Trends() {
const dispatch = useDispatch();
  const { eventResults} = useSelector(({betting}) => betting);
 
  const [values, setValues] = useState({meron: 0, wala: 0, cancelled: 0, draw: 0})
  const [maxWin, setMaxWin] = useState([
])


  useEffect(() => {
  
    if(eventResults){
      const { resultCounts, results } = eventResults;
    
      if(results){
        setMaxWin(groupResultsmaxWin(results))
      }
      if(resultCounts){
        setValues(resultCounts);
      }
    }
  }, [eventResults])

  return (
  <>
  

   
    <Typography variant='subtitle2' className='text-white'>TRENDS</Typography>
    <div className='trend-summary-wrapper'> 
        <button className='trend-item-container v-theme-dark'>
        <div className='bg-meron trend-summary-count'>
        <span>{values.meron}</span>
        </div>&nbsp;&nbsp;
        <div className='trend-summary-title-container'>
        <span>MERON</span>
        </div>&nbsp;&nbsp;
        </button>&nbsp;&nbsp;
        <button className='trend-item-container v-theme-dark'>
        <div className='bg-wala trend-summary-count'>
        <span>{values.wala}</span>
        </div>&nbsp;&nbsp;
        <div className='trend-summary-title-container'>
        <span>WALA</span>
        </div>&nbsp;&nbsp;
        </button>&nbsp;&nbsp;
        <button className='trend-item-container v-theme-dark'>
        <div className='bg-draw trend-summary-count '>
        <span>{values.draw}</span>
        </div>&nbsp;&nbsp;
        <div className='trend-summary-title-container'>
        <span>DRAW</span>
        </div>&nbsp;&nbsp;
        </button>&nbsp;&nbsp;
        <button className='trend-item-container v-theme-dark'>
        <div className='bg-cancelled trend-summary-count'>
             <span >{values.cancelled}</span>
        </div>&nbsp;&nbsp;
        <div className='trend-summary-title-container'>
                <span className="text-10">CANCELLED</span> 
        </div>&nbsp;&nbsp;
        </button>
    </div>
    <Divider className='mt-3 mb-3 divider-bot' />
    <div className='trends-table'>
    
    <TrendsTable eventResults={maxWin}/>

    </div>
  
  
  </>
  )
}
