export const configs = {
    file_url: "https://files.bugtech.online/api/v1/files",
    uploadId: "6687c0bbfc46a179ec24089f",
    file_token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY2NmUwNjVjOTA5ZGE4ZTEzMWFkMDUwZSIsImlhdCI6MTcyMDE3MjY4NH0.yIyHWzZAznYshtXqLFrnY2lbiLsuxsTfOJ61wG8SAH4"
}


export const GCashAccounts = [{
    number: "09165223072",
    name: "R**L T.",
}]