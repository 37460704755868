import { CLEAR_ERRORS, SET_MODAL, SET_ERRORS, SET_LOADING, STOP_LOADING, SET_EVENT_FORM, SET_MODAL_SUPPORT, CLEAR_EVENT_FORM, SET_UNSELECTED, SET_SELECTED, SET_STREAMING, STOP_STREAMING, SET_MEDIA_WIDTH, SET_MODAL_RULES, SET_MODAL_POINTS, CLOSE_MODALS, SET_ACTIVE_TAB, SET_MODAL_SETTINGS, SET_MODAL_PROMO, SET_MODAL_TRANSACTIONS, SET_MODAL_CASH_REQUESTS, SET_MODAL_WITHDRAWAL, SET_MODAL_BET, SET_SNACKBAR, CLEAR_SNACKBAR, SET_MODAL_SPIN, SET_MODAL_USERS } from "../actions/types";



const INIT_EVENT = {
  title: '',
  subtitle: '',
  duration: 0,
  matches: [],
  totalFight: 0,
  isStreaming: false
}


const INIT_STATE = {
    loading: false,
    errors: {},
    eventForm: INIT_EVENT,
    selected: [],
    unselected: [],
    selectedCount: 0,
    unselectedCount: 0,
    isStreaming: true,
    isWeb: false,
    activeTab: 1,
    rulesModal: false,
    usersModal: false,
    settingsModal: false,
    supportModal: false,
    promoModal: false,
    transactionsModal: false,
    cashRequestsModal: false,
    pointsModal: false,
    withdrawalModal: false,
    betModal: null,
    spinModal: false,
    modal: null,
    snackbar: { 
      message: '',
      type: 'error',
      color: 'danger'
    }
};

export default (state = INIT_STATE, action) => {
  const {type, payload} = action;

  switch (type) {
    
    case SET_MODAL_RULES: {
      return {
        ...state,
        rulesModal: true
      }
    }
    case SET_MODAL_USERS: {
      return {
        ...state,
        usersModal: true
      }
    }
    
    case SET_MODAL: {
      return {
        ...state,
        modal: payload
      }
    }
        
    case SET_MODAL_WITHDRAWAL: {
      return {
        ...state,
        withdrawalModal: true
      }
    }
    
    case SET_MODAL_BET: {
      return {
        ...state,
        betModal: payload
      }
    }
       
    case SET_MODAL_SETTINGS: {
      return {
        ...state,
        settingsModal: true
      }
    }

    case SET_MODAL_SPIN: {
      return {
        ...state,
        spinModal: true
      }
    }
    
    case SET_MODAL_SUPPORT: {
      return {
        ...state,
        supportModal: true
      }
    }
       
    case SET_MODAL_PROMO: {
      return {
        ...state,
        promoModal: true
      }
    }
    
       
    case SET_MODAL_TRANSACTIONS: {
      return {
        ...state,
        transactionsModal: true
      }
    }
    
       
    case SET_MODAL_CASH_REQUESTS: {
      return {
        ...state,
        cashRequestsModal: true
      }
    }
    
    case SET_MODAL_POINTS: {
      return {
        ...state,
        pointsModal: true
      }
    }
  
    case CLOSE_MODALS: {
      return {
        ...state,
        rulesModal: false,
        pointsModal: false,
        settingsModal: false,
        supportModal: false,
        promoModal: false,
        transactionsModal: false,
        cashRequestsModal: false,
        pointsModal: false,
        withdrawalModal: false,
        betModal: null,
        usersModal: false,
        spinModal: false,
        modal: null
      }
    }
    
      
    case SET_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: payload
      }
    }
  
    case SET_LOADING: {
      return {
        ...state,
        loading:true
      };
    }

    case SET_MEDIA_WIDTH: {
      return {
        ...state,
        isWeb: payload 
      };
    }


    case STOP_LOADING: {
      return {
        ...state,
        loading:false
      };
    }

    case SET_STREAMING: {
      return {
        ...state,
        isStreaming: true
      };
    }

    case STOP_STREAMING: {
      return {
        ...state,
        isStreaming: false
      };
    }

    case SET_ERRORS: {
      return {
        ...state,
        errors: { ...state.errors, ...action.payload}
      };
    }

    case SET_SELECTED: {
      return {
        ...state,
        selected: action.payload,
        selectedCount: action.payload ? action.payload.length : 0
      };
    }

    case SET_UNSELECTED: {
      return {
        ...state,
        unselected: action.payload,
        unselectedCount: action.payload ? action.payload.length : 0
      };
    }

    case SET_SNACKBAR: {
      return {
        ...state,
        snackbar: action.payload
       };
    }



    case CLEAR_SNACKBAR: {
      return {
        ...state,
        snackbar: {message: '', type: 'warning'}
       };
    }



    case CLEAR_EVENT_FORM: {
      return {
        ...state,
        eventForm: INIT_EVENT 
       };
    }


    case CLEAR_ERRORS: {
      return {
        ...state,
        errors: {}
      };
    }



    default:
      return state;
  }
};
